/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n    mutation AddMessage($input: AddMessageInput!) {\n        addMessage(input: $input) {\n            id\n        }\n    }\n": types.AddMessageDocument,
    "\n    mutation UpsertParticipant($input: UpsertUserInput!) {\n        upsertParticipant(input: $input) {\n            id\n        }\n    }\n": types.UpsertParticipantDocument,
    "\n    mutation AddActivityLog($input: AddActivityLogInput!) {\n        addActivityLog(input: $input) {\n            id\n        }\n    }\n": types.AddActivityLogDocument,
    "\n    mutation ApproveActivityLog($input: AddApproveLogInput!) {\n        approveActivityLog(input: $input) {\n            id\n        }\n    }\n": types.ApproveActivityLogDocument,
    "\n    mutation AddFeedback($input: AddFeedbackInput, $file: Upload) {\n        addFeedback(input: $input, file: $file)\n    }\n": types.AddFeedbackDocument,
    "\n    mutation AddMessageFeedback($input: AddMessageFeedbackInput!) {\n        addMessageFeedback(input: $input) {\n            id\n        }\n    }\n": types.AddMessageFeedbackDocument,
    "\n    mutation MarkMessagesAsRead($input: MarkMessageAsReadInput!) {\n        markMessagesAsRead(input: $input) {\n            id\n        }\n    }\n": types.MarkMessagesAsReadDocument,
    "\n    mutation UpdateConversation($input: UpdateConversationInput!) {\n        updateConversation(input: $input) {\n            id\n        }\n    }\n": types.UpdateConversationDocument,
    "\n    mutation ActionOnDislikedMessage($input: ActionOnDislikedMessageInput!) {\n        actionOnDislikedMessage(input: $input)\n    }\n": types.ActionOnDislikedMessageDocument,
    "\n    mutation GenerateRequirements($input: GenerateRequirementsInput) {\n        generateRequirements(input: $input)\n    }\n": types.GenerateRequirementsDocument,
    "\n    mutation GenerateJiraTickets($input: GenerateJiraTicketsInput!) {\n        generateJiraTickets(input: $input)\n    }\n": types.GenerateJiraTicketsDocument,
    "\n    mutation GenerateCodeFromRequirements($input: GenerateCodeFromRequirementsInput!) {\n        generateCodeFromRequirements(input: $input)\n    }\n": types.GenerateCodeFromRequirementsDocument,
    "\n    mutation StopGeneration($input: StopGenerationInput!) {\n        stopGeneration(input: $input)\n    }\n": types.StopGenerationDocument,
    "\n    mutation saveNewDocuments($input: SaveNewDocumentsInput!) {\n        saveNewDocuments(input: $input)\n    }\n": types.SaveNewDocumentsDocument,
    "\n    mutation DeleteDocument($input: DeleteDocumentInput!) {\n        deleteDocument(input: $input)\n    }\n": types.DeleteDocumentDocument,
    "\n    mutation DeleteFaissIndex($input: DeleteIndexInput!) {\n        deleteFaissIndex(input: $input)\n    }\n": types.DeleteFaissIndexDocument,
    "\n    mutation GenerateSprintAnalysisReport($input: GenerateSprintAnalysisReportInput!) {\n        generateSprintAnalysisReport(input: $input) {\n            overview\n            report\n        }\n    }\n": types.GenerateSprintAnalysisReportDocument,
    "\n    mutation UploadTimesheet($input: AddTimesheetInput!) {\n        uploadTimesheet(input: $input)\n    }\n": types.UploadTimesheetDocument,
    "\n    mutation SaveNewCallReports($input: SaveNewDocumentsInput!) {\n        saveNewCallReports(input: $input) {\n            option\n            question\n            enabled\n        }\n    }\n": types.SaveNewCallReportsDocument,
    "\n    mutation AnalyzeCallTranscript($input: AnalyzeCallTranscriptInput!) {\n        analyzeCallTranscript(input: $input)\n    }\n": types.AnalyzeCallTranscriptDocument,
    "\n    mutation AnalyzeTestCases($input: AnalyzeTestCasesInput!) {\n        analyzeTestCases(input: $input)\n    }\n": types.AnalyzeTestCasesDocument,
    "\n    mutation AddFactCheckMessage($input: AddFactCheckMessageInput!) {\n        addFactCheckMessage(input: $input) {\n            id\n            model\n            conversationId\n            content\n            author\n            type\n            sources {\n                id\n                domainUrl\n                sources\n            }\n        }\n    }\n": types.AddFactCheckMessageDocument,
    "\n    mutation CompareLLMResponses($input: LLMComparisonInput) {\n        compareLLMResponses(input: $input)\n    }\n": types.CompareLlmResponsesDocument,
    "\n    mutation GetHighlightedPDF($input: GetHighlightedPDFInput!) {\n        getHighlightedPDF(input: $input) {\n            page\n            pdf\n            source\n        }\n    }\n": types.GetHighlightedPdfDocument,
    "\n    mutation RemoveKPLConfiguration($configKey: String!) {\n        removeKPLConfiguration(configKey: $configKey)\n    }\n": types.RemoveKplConfigurationDocument,
    "\n    mutation AddKPLConfiguration($input: KPLConfigurationInput!) {\n        addKPLConfiguration(input: $input) {\n            id\n        }\n    }\n": types.AddKplConfigurationDocument,
    "\n    mutation UpdateKPLConfiguration($input: KPLConfigurationInput!) {\n        updateKPLConfiguration(input: $input) {\n            id\n        }\n    }\n": types.UpdateKplConfigurationDocument,
    "\n    mutation AddPromptTemplate($input: CreatePromptTemplateInput!) {\n        addPromptTemplate(input: $input) {\n            id\n        }\n    }\n": types.AddPromptTemplateDocument,
    "\n    mutation UpdatePromptTemplate($input: UpdatePromptTemplateInput!) {\n        updatePromptTemplate(input: $input) {\n            id\n        }\n    }\n": types.UpdatePromptTemplateDocument,
    "\n    mutation DeletePromptTemplate($deletePromptTemplateId: Int!) {\n        deletePromptTemplate(id: $deletePromptTemplateId)\n    }\n": types.DeletePromptTemplateDocument,
    "\n    mutation UpdateUser($input: UpdateUserInput!) {\n        updateUser(input: $input)\n    }\n": types.UpdateUserDocument,
    "\n    mutation CreateNewUser($input: NewUserInput!) {\n        createNewUser(input: $input)\n    }\n": types.CreateNewUserDocument,
    "\n    query GetMessages($input: GetMessageInput!) {\n        getMessages(input: $input) {\n            id\n            replyTo\n            content\n            author\n        }\n    }\n": types.GetMessagesDocument,
    "\n    query GetParticipant($userId: ID!) {\n        getParticipant(userId: $userId) {\n            conversations {\n                id\n                title\n                updatedAt\n                threadId\n                threadType\n            }\n        }\n    }\n": types.GetParticipantDocument,
    "\n    query GetSourceChunks($sourceId: ID!) {\n        getSourceChunks(sourceId: $sourceId)\n    }\n": types.GetSourceChunksDocument,
    "\n    query GetApplicationConfig($enabled: Boolean) {\n        getApplicationConfig(enabled: $enabled) {\n            configKey\n            chatType\n            description\n            icon\n            id\n            enabled\n            metadata\n            title\n        }\n    }\n": types.GetApplicationConfigDocument,
    "\n    query GetApplicationUserConfig {\n        getApplicationUserConfig {\n            email\n            features\n            metadata\n            models\n            override\n        }\n    }\n": types.GetApplicationUserConfigDocument,
    "\n    query GetApplicationUserConfigByEmail($email: String!) {\n        getApplicationUserConfigByEmail(email: $email) {\n            email\n            features\n            metadata\n            models\n            override\n        }\n    }\n": types.GetApplicationUserConfigByEmailDocument,
    "\n    query GetKplGuidelines($input: GetKplGuidelinesInput!) {\n        getKplGuidelines(input: $input) {\n            guidelinesText\n        }\n    }\n": types.GetKplGuidelinesDocument,
    "\n    query GetJiraSettings($input: GetJiraSettingsInput!) {\n        getJiraSettings(input: $input) {\n            id\n            key\n            name\n            sprints {\n                id\n                name\n                originBoardId\n                state\n            }\n        }\n    }\n": types.GetJiraSettingsDocument,
    "\n    query GetLiveCustomerAgentDetails {\n        getLiveCustomerAgentDetails {\n            audioFileUrl\n            transcript {\n                message\n                time\n                type\n            }\n        }\n    }\n": types.GetLiveCustomerAgentDetailsDocument,
    "\n    query GetAllKPLConfigurations($input: GetAllKPLConfigurationsInput!) {\n        getAllKPLConfigurations(input: $input) {\n            kpls {\n                id\n                enabled\n                configKey\n                description\n                chatType\n                metadata\n                title\n                icon\n            }\n            page\n            pageSize\n            totalCount\n            totalPages\n        }\n    }\n": types.GetAllKplConfigurationsDocument,
    "\n    query GetKPLByConfigKey($configKey: String!) {\n        getKPLByConfigKey(configKey: $configKey) {\n            id\n            icon\n            enabled\n            description\n            configKey\n            chatType\n            metadata\n            title\n            domains\n            users\n            guidelines {\n                guidelinesText\n            }\n            prompts {\n                model\n                category\n                prompt\n                variables\n            }\n        }\n    }\n": types.GetKplByConfigKeyDocument,
    "\n    query GetExistingKPLUsers {\n        getExistingKPLUsers {\n            domains\n            users\n        }\n    }\n": types.GetExistingKplUsersDocument,
    "\n    query GetPromptTemplateByCriteria($criteria: GetPromptTemplateByCriteriaInput!) {\n        getPromptTemplateByCriteria(criteria: $criteria) {\n            page\n            pageSize\n            promptTemplates {\n                category\n                id\n                metadata\n                templateContent\n                templateKey\n                variables\n            }\n            totalCount\n            totalPages\n        }\n    }\n": types.GetPromptTemplateByCriteriaDocument,
    "\n    query GetAllUsers($input: GetAllUserConfigurationsInput!) {\n        getAllUsers(input: $input) {\n            users {\n                metadata\n                lastName\n                firstName\n                features\n                email\n                models\n                override\n            }\n            totalPages\n            totalCount\n            pageSize\n            page\n        }\n    }\n": types.GetAllUsersDocument,
    "\n    query GetUserByUserName($email: String!) {\n        getUserByUserName(email: $email) {\n            firstName\n            lastName\n            email\n            models\n            features\n            metadata\n            override\n        }\n    }\n": types.GetUserByUserNameDocument,
    "\n    subscription NewMessage($input: MessageSubscriptionInput!) {\n        newMessage(input: $input) {\n            id\n            content\n            previousContent\n            author\n            processing\n            feedback\n            type\n            sources {\n                id\n                sources\n                domainUrl\n            }\n        }\n    }\n": types.NewMessageDocument,
    "\n    subscription NewWorklog($input: WorklogSubscriptionInput!) {\n        newWorklog(input: $input) {\n            id\n            isUser\n            description\n            showApprove\n            type\n        }\n    }\n": types.NewWorklogDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation AddMessage($input: AddMessageInput!) {\n        addMessage(input: $input) {\n            id\n        }\n    }\n"): (typeof documents)["\n    mutation AddMessage($input: AddMessageInput!) {\n        addMessage(input: $input) {\n            id\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation UpsertParticipant($input: UpsertUserInput!) {\n        upsertParticipant(input: $input) {\n            id\n        }\n    }\n"): (typeof documents)["\n    mutation UpsertParticipant($input: UpsertUserInput!) {\n        upsertParticipant(input: $input) {\n            id\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation AddActivityLog($input: AddActivityLogInput!) {\n        addActivityLog(input: $input) {\n            id\n        }\n    }\n"): (typeof documents)["\n    mutation AddActivityLog($input: AddActivityLogInput!) {\n        addActivityLog(input: $input) {\n            id\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation ApproveActivityLog($input: AddApproveLogInput!) {\n        approveActivityLog(input: $input) {\n            id\n        }\n    }\n"): (typeof documents)["\n    mutation ApproveActivityLog($input: AddApproveLogInput!) {\n        approveActivityLog(input: $input) {\n            id\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation AddFeedback($input: AddFeedbackInput, $file: Upload) {\n        addFeedback(input: $input, file: $file)\n    }\n"): (typeof documents)["\n    mutation AddFeedback($input: AddFeedbackInput, $file: Upload) {\n        addFeedback(input: $input, file: $file)\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation AddMessageFeedback($input: AddMessageFeedbackInput!) {\n        addMessageFeedback(input: $input) {\n            id\n        }\n    }\n"): (typeof documents)["\n    mutation AddMessageFeedback($input: AddMessageFeedbackInput!) {\n        addMessageFeedback(input: $input) {\n            id\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation MarkMessagesAsRead($input: MarkMessageAsReadInput!) {\n        markMessagesAsRead(input: $input) {\n            id\n        }\n    }\n"): (typeof documents)["\n    mutation MarkMessagesAsRead($input: MarkMessageAsReadInput!) {\n        markMessagesAsRead(input: $input) {\n            id\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation UpdateConversation($input: UpdateConversationInput!) {\n        updateConversation(input: $input) {\n            id\n        }\n    }\n"): (typeof documents)["\n    mutation UpdateConversation($input: UpdateConversationInput!) {\n        updateConversation(input: $input) {\n            id\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation ActionOnDislikedMessage($input: ActionOnDislikedMessageInput!) {\n        actionOnDislikedMessage(input: $input)\n    }\n"): (typeof documents)["\n    mutation ActionOnDislikedMessage($input: ActionOnDislikedMessageInput!) {\n        actionOnDislikedMessage(input: $input)\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation GenerateRequirements($input: GenerateRequirementsInput) {\n        generateRequirements(input: $input)\n    }\n"): (typeof documents)["\n    mutation GenerateRequirements($input: GenerateRequirementsInput) {\n        generateRequirements(input: $input)\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation GenerateJiraTickets($input: GenerateJiraTicketsInput!) {\n        generateJiraTickets(input: $input)\n    }\n"): (typeof documents)["\n    mutation GenerateJiraTickets($input: GenerateJiraTicketsInput!) {\n        generateJiraTickets(input: $input)\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation GenerateCodeFromRequirements($input: GenerateCodeFromRequirementsInput!) {\n        generateCodeFromRequirements(input: $input)\n    }\n"): (typeof documents)["\n    mutation GenerateCodeFromRequirements($input: GenerateCodeFromRequirementsInput!) {\n        generateCodeFromRequirements(input: $input)\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation StopGeneration($input: StopGenerationInput!) {\n        stopGeneration(input: $input)\n    }\n"): (typeof documents)["\n    mutation StopGeneration($input: StopGenerationInput!) {\n        stopGeneration(input: $input)\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation saveNewDocuments($input: SaveNewDocumentsInput!) {\n        saveNewDocuments(input: $input)\n    }\n"): (typeof documents)["\n    mutation saveNewDocuments($input: SaveNewDocumentsInput!) {\n        saveNewDocuments(input: $input)\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation DeleteDocument($input: DeleteDocumentInput!) {\n        deleteDocument(input: $input)\n    }\n"): (typeof documents)["\n    mutation DeleteDocument($input: DeleteDocumentInput!) {\n        deleteDocument(input: $input)\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation DeleteFaissIndex($input: DeleteIndexInput!) {\n        deleteFaissIndex(input: $input)\n    }\n"): (typeof documents)["\n    mutation DeleteFaissIndex($input: DeleteIndexInput!) {\n        deleteFaissIndex(input: $input)\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation GenerateSprintAnalysisReport($input: GenerateSprintAnalysisReportInput!) {\n        generateSprintAnalysisReport(input: $input) {\n            overview\n            report\n        }\n    }\n"): (typeof documents)["\n    mutation GenerateSprintAnalysisReport($input: GenerateSprintAnalysisReportInput!) {\n        generateSprintAnalysisReport(input: $input) {\n            overview\n            report\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation UploadTimesheet($input: AddTimesheetInput!) {\n        uploadTimesheet(input: $input)\n    }\n"): (typeof documents)["\n    mutation UploadTimesheet($input: AddTimesheetInput!) {\n        uploadTimesheet(input: $input)\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation SaveNewCallReports($input: SaveNewDocumentsInput!) {\n        saveNewCallReports(input: $input) {\n            option\n            question\n            enabled\n        }\n    }\n"): (typeof documents)["\n    mutation SaveNewCallReports($input: SaveNewDocumentsInput!) {\n        saveNewCallReports(input: $input) {\n            option\n            question\n            enabled\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation AnalyzeCallTranscript($input: AnalyzeCallTranscriptInput!) {\n        analyzeCallTranscript(input: $input)\n    }\n"): (typeof documents)["\n    mutation AnalyzeCallTranscript($input: AnalyzeCallTranscriptInput!) {\n        analyzeCallTranscript(input: $input)\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation AnalyzeTestCases($input: AnalyzeTestCasesInput!) {\n        analyzeTestCases(input: $input)\n    }\n"): (typeof documents)["\n    mutation AnalyzeTestCases($input: AnalyzeTestCasesInput!) {\n        analyzeTestCases(input: $input)\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation AddFactCheckMessage($input: AddFactCheckMessageInput!) {\n        addFactCheckMessage(input: $input) {\n            id\n            model\n            conversationId\n            content\n            author\n            type\n            sources {\n                id\n                domainUrl\n                sources\n            }\n        }\n    }\n"): (typeof documents)["\n    mutation AddFactCheckMessage($input: AddFactCheckMessageInput!) {\n        addFactCheckMessage(input: $input) {\n            id\n            model\n            conversationId\n            content\n            author\n            type\n            sources {\n                id\n                domainUrl\n                sources\n            }\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation CompareLLMResponses($input: LLMComparisonInput) {\n        compareLLMResponses(input: $input)\n    }\n"): (typeof documents)["\n    mutation CompareLLMResponses($input: LLMComparisonInput) {\n        compareLLMResponses(input: $input)\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation GetHighlightedPDF($input: GetHighlightedPDFInput!) {\n        getHighlightedPDF(input: $input) {\n            page\n            pdf\n            source\n        }\n    }\n"): (typeof documents)["\n    mutation GetHighlightedPDF($input: GetHighlightedPDFInput!) {\n        getHighlightedPDF(input: $input) {\n            page\n            pdf\n            source\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation RemoveKPLConfiguration($configKey: String!) {\n        removeKPLConfiguration(configKey: $configKey)\n    }\n"): (typeof documents)["\n    mutation RemoveKPLConfiguration($configKey: String!) {\n        removeKPLConfiguration(configKey: $configKey)\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation AddKPLConfiguration($input: KPLConfigurationInput!) {\n        addKPLConfiguration(input: $input) {\n            id\n        }\n    }\n"): (typeof documents)["\n    mutation AddKPLConfiguration($input: KPLConfigurationInput!) {\n        addKPLConfiguration(input: $input) {\n            id\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation UpdateKPLConfiguration($input: KPLConfigurationInput!) {\n        updateKPLConfiguration(input: $input) {\n            id\n        }\n    }\n"): (typeof documents)["\n    mutation UpdateKPLConfiguration($input: KPLConfigurationInput!) {\n        updateKPLConfiguration(input: $input) {\n            id\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation AddPromptTemplate($input: CreatePromptTemplateInput!) {\n        addPromptTemplate(input: $input) {\n            id\n        }\n    }\n"): (typeof documents)["\n    mutation AddPromptTemplate($input: CreatePromptTemplateInput!) {\n        addPromptTemplate(input: $input) {\n            id\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation UpdatePromptTemplate($input: UpdatePromptTemplateInput!) {\n        updatePromptTemplate(input: $input) {\n            id\n        }\n    }\n"): (typeof documents)["\n    mutation UpdatePromptTemplate($input: UpdatePromptTemplateInput!) {\n        updatePromptTemplate(input: $input) {\n            id\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation DeletePromptTemplate($deletePromptTemplateId: Int!) {\n        deletePromptTemplate(id: $deletePromptTemplateId)\n    }\n"): (typeof documents)["\n    mutation DeletePromptTemplate($deletePromptTemplateId: Int!) {\n        deletePromptTemplate(id: $deletePromptTemplateId)\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation UpdateUser($input: UpdateUserInput!) {\n        updateUser(input: $input)\n    }\n"): (typeof documents)["\n    mutation UpdateUser($input: UpdateUserInput!) {\n        updateUser(input: $input)\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation CreateNewUser($input: NewUserInput!) {\n        createNewUser(input: $input)\n    }\n"): (typeof documents)["\n    mutation CreateNewUser($input: NewUserInput!) {\n        createNewUser(input: $input)\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query GetMessages($input: GetMessageInput!) {\n        getMessages(input: $input) {\n            id\n            replyTo\n            content\n            author\n        }\n    }\n"): (typeof documents)["\n    query GetMessages($input: GetMessageInput!) {\n        getMessages(input: $input) {\n            id\n            replyTo\n            content\n            author\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query GetParticipant($userId: ID!) {\n        getParticipant(userId: $userId) {\n            conversations {\n                id\n                title\n                updatedAt\n                threadId\n                threadType\n            }\n        }\n    }\n"): (typeof documents)["\n    query GetParticipant($userId: ID!) {\n        getParticipant(userId: $userId) {\n            conversations {\n                id\n                title\n                updatedAt\n                threadId\n                threadType\n            }\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query GetSourceChunks($sourceId: ID!) {\n        getSourceChunks(sourceId: $sourceId)\n    }\n"): (typeof documents)["\n    query GetSourceChunks($sourceId: ID!) {\n        getSourceChunks(sourceId: $sourceId)\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query GetApplicationConfig($enabled: Boolean) {\n        getApplicationConfig(enabled: $enabled) {\n            configKey\n            chatType\n            description\n            icon\n            id\n            enabled\n            metadata\n            title\n        }\n    }\n"): (typeof documents)["\n    query GetApplicationConfig($enabled: Boolean) {\n        getApplicationConfig(enabled: $enabled) {\n            configKey\n            chatType\n            description\n            icon\n            id\n            enabled\n            metadata\n            title\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query GetApplicationUserConfig {\n        getApplicationUserConfig {\n            email\n            features\n            metadata\n            models\n            override\n        }\n    }\n"): (typeof documents)["\n    query GetApplicationUserConfig {\n        getApplicationUserConfig {\n            email\n            features\n            metadata\n            models\n            override\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query GetApplicationUserConfigByEmail($email: String!) {\n        getApplicationUserConfigByEmail(email: $email) {\n            email\n            features\n            metadata\n            models\n            override\n        }\n    }\n"): (typeof documents)["\n    query GetApplicationUserConfigByEmail($email: String!) {\n        getApplicationUserConfigByEmail(email: $email) {\n            email\n            features\n            metadata\n            models\n            override\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query GetKplGuidelines($input: GetKplGuidelinesInput!) {\n        getKplGuidelines(input: $input) {\n            guidelinesText\n        }\n    }\n"): (typeof documents)["\n    query GetKplGuidelines($input: GetKplGuidelinesInput!) {\n        getKplGuidelines(input: $input) {\n            guidelinesText\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query GetJiraSettings($input: GetJiraSettingsInput!) {\n        getJiraSettings(input: $input) {\n            id\n            key\n            name\n            sprints {\n                id\n                name\n                originBoardId\n                state\n            }\n        }\n    }\n"): (typeof documents)["\n    query GetJiraSettings($input: GetJiraSettingsInput!) {\n        getJiraSettings(input: $input) {\n            id\n            key\n            name\n            sprints {\n                id\n                name\n                originBoardId\n                state\n            }\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query GetLiveCustomerAgentDetails {\n        getLiveCustomerAgentDetails {\n            audioFileUrl\n            transcript {\n                message\n                time\n                type\n            }\n        }\n    }\n"): (typeof documents)["\n    query GetLiveCustomerAgentDetails {\n        getLiveCustomerAgentDetails {\n            audioFileUrl\n            transcript {\n                message\n                time\n                type\n            }\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query GetAllKPLConfigurations($input: GetAllKPLConfigurationsInput!) {\n        getAllKPLConfigurations(input: $input) {\n            kpls {\n                id\n                enabled\n                configKey\n                description\n                chatType\n                metadata\n                title\n                icon\n            }\n            page\n            pageSize\n            totalCount\n            totalPages\n        }\n    }\n"): (typeof documents)["\n    query GetAllKPLConfigurations($input: GetAllKPLConfigurationsInput!) {\n        getAllKPLConfigurations(input: $input) {\n            kpls {\n                id\n                enabled\n                configKey\n                description\n                chatType\n                metadata\n                title\n                icon\n            }\n            page\n            pageSize\n            totalCount\n            totalPages\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query GetKPLByConfigKey($configKey: String!) {\n        getKPLByConfigKey(configKey: $configKey) {\n            id\n            icon\n            enabled\n            description\n            configKey\n            chatType\n            metadata\n            title\n            domains\n            users\n            guidelines {\n                guidelinesText\n            }\n            prompts {\n                model\n                category\n                prompt\n                variables\n            }\n        }\n    }\n"): (typeof documents)["\n    query GetKPLByConfigKey($configKey: String!) {\n        getKPLByConfigKey(configKey: $configKey) {\n            id\n            icon\n            enabled\n            description\n            configKey\n            chatType\n            metadata\n            title\n            domains\n            users\n            guidelines {\n                guidelinesText\n            }\n            prompts {\n                model\n                category\n                prompt\n                variables\n            }\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query GetExistingKPLUsers {\n        getExistingKPLUsers {\n            domains\n            users\n        }\n    }\n"): (typeof documents)["\n    query GetExistingKPLUsers {\n        getExistingKPLUsers {\n            domains\n            users\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query GetPromptTemplateByCriteria($criteria: GetPromptTemplateByCriteriaInput!) {\n        getPromptTemplateByCriteria(criteria: $criteria) {\n            page\n            pageSize\n            promptTemplates {\n                category\n                id\n                metadata\n                templateContent\n                templateKey\n                variables\n            }\n            totalCount\n            totalPages\n        }\n    }\n"): (typeof documents)["\n    query GetPromptTemplateByCriteria($criteria: GetPromptTemplateByCriteriaInput!) {\n        getPromptTemplateByCriteria(criteria: $criteria) {\n            page\n            pageSize\n            promptTemplates {\n                category\n                id\n                metadata\n                templateContent\n                templateKey\n                variables\n            }\n            totalCount\n            totalPages\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query GetAllUsers($input: GetAllUserConfigurationsInput!) {\n        getAllUsers(input: $input) {\n            users {\n                metadata\n                lastName\n                firstName\n                features\n                email\n                models\n                override\n            }\n            totalPages\n            totalCount\n            pageSize\n            page\n        }\n    }\n"): (typeof documents)["\n    query GetAllUsers($input: GetAllUserConfigurationsInput!) {\n        getAllUsers(input: $input) {\n            users {\n                metadata\n                lastName\n                firstName\n                features\n                email\n                models\n                override\n            }\n            totalPages\n            totalCount\n            pageSize\n            page\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query GetUserByUserName($email: String!) {\n        getUserByUserName(email: $email) {\n            firstName\n            lastName\n            email\n            models\n            features\n            metadata\n            override\n        }\n    }\n"): (typeof documents)["\n    query GetUserByUserName($email: String!) {\n        getUserByUserName(email: $email) {\n            firstName\n            lastName\n            email\n            models\n            features\n            metadata\n            override\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    subscription NewMessage($input: MessageSubscriptionInput!) {\n        newMessage(input: $input) {\n            id\n            content\n            previousContent\n            author\n            processing\n            feedback\n            type\n            sources {\n                id\n                sources\n                domainUrl\n            }\n        }\n    }\n"): (typeof documents)["\n    subscription NewMessage($input: MessageSubscriptionInput!) {\n        newMessage(input: $input) {\n            id\n            content\n            previousContent\n            author\n            processing\n            feedback\n            type\n            sources {\n                id\n                sources\n                domainUrl\n            }\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    subscription NewWorklog($input: WorklogSubscriptionInput!) {\n        newWorklog(input: $input) {\n            id\n            isUser\n            description\n            showApprove\n            type\n        }\n    }\n"): (typeof documents)["\n    subscription NewWorklog($input: WorklogSubscriptionInput!) {\n        newWorklog(input: $input) {\n            id\n            isUser\n            description\n            showApprove\n            type\n        }\n    }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;