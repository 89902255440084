import { Dropdown, FilterCard, Link, SearchInput } from '@kaya/kaya-ui-design-system-pb';
import { useState } from 'react';
import { UserType } from '../../../__generated__/graphql';
import { ALL_VALUE } from '../../../constants';
import { KPLSelect, ModelSelect } from '../custom-select-components';
import { IAdminFilterUserContainerProps } from '../user-management-container';

type IHideShowUserFilterType = {
    showCreator: boolean;
    showModel: boolean;
    showFeature: boolean;
    showUserType: boolean;
};

export const UserFilterContainer = ({ filterUserCriteria }: IAdminFilterUserContainerProps) => {
    const { model, setModel, setFeature, feature, userType, setUserType } = filterUserCriteria;
    const [manageUserFilter, setManageUserFilter] = useState<IHideShowUserFilterType>({
        showFeature: true,
        showModel: true,
        showCreator: true,
        showUserType: true,
    });

    // Function to clear all filters
    const handleClearFilter = () => {
        setManageUserFilter({
            showFeature: false,
            showModel: false,
            showCreator: false,
            showUserType: false,
        });
    };

    return (
        <div className="admin-panel__filter-section sm:w-full min-w-[296px] flex flex-col gap-y-6">
            <div className="min-h-[36px] flex items-center justify-between">
                <p className="text-[18px] font-600">Filter</p>
                <Dropdown
                    wrapperClassName="z-[9999]"
                    selectionType="multiple"
                    triggerAction="onClick"
                    header={{
                        type: 'title',
                        title: (
                            <Link appearance="neutral" iconBefore="ri-delete-bin-6-line" onClick={handleClearFilter}>
                                Clear all filters
                            </Link>
                        ),
                    }}
                    dropdownItems={[
                        {
                            id: 1,
                            label: 'Models',
                            defaultChecked: manageUserFilter.showModel,
                            onClick: () => {
                                setManageUserFilter({ ...manageUserFilter, showModel: !manageUserFilter.showModel });
                            },
                        },
                        {
                            id: 2,
                            label: 'User Type',
                            defaultChecked: manageUserFilter.showUserType,
                            onClick: () => {
                                setManageUserFilter({
                                    ...manageUserFilter,
                                    showUserType: !manageUserFilter.showUserType,
                                });
                            },
                        },
                        {
                            id: 3,
                            label: 'KPLs',
                            defaultChecked: manageUserFilter.showFeature,
                            onClick: () => {
                                setManageUserFilter({
                                    ...manageUserFilter,
                                    showFeature: !manageUserFilter.showFeature,
                                });
                            },
                        },
                    ]}
                >
                    <Link appearance="neutral" iconBefore="ri-equalizer-line">
                        Manage filters
                    </Link>
                </Dropdown>
            </div>
            <div className="admin-panel__filter-card-container flex flex-col border border-N-200 border-b-0">
                {/* Models */}
                <FilterCard
                    title="Models"
                    showClear={model !== ALL_VALUE ? true : false}
                    show={manageUserFilter.showModel}
                    handleClear={() => {
                        setModel(ALL_VALUE);
                    }}
                >
                    <div className="flex flex-col gap-y-[12px]">
                        <ModelSelect
                            hasAllOption={true}
                            value={model}
                            onChange={(e: any) => {
                                setModel(e.name === ALL_VALUE ? e.name : e.model);
                            }}
                        />
                    </div>
                </FilterCard>

                <FilterCard
                    title="User Type"
                    showClear={userType !== ALL_VALUE ? true : false}
                    show={manageUserFilter.showUserType}
                    handleClear={() => {
                        setUserType(ALL_VALUE);
                    }}
                >
                    <div className="flex flex-col gap-y-[12px]">
                        <SearchInput
                            className="w-full"
                            placeholder="Search User Type"
                            value={userType || ''}
                            view="default"
                            items={[UserType.Domain, UserType.User, ALL_VALUE]}
                            onChange={(e: any) => {
                                setUserType(e);
                            }}
                        />
                    </div>
                </FilterCard>

                <FilterCard
                    title="KPL"
                    showClear={feature.length !== 0 ? true : false}
                    show={manageUserFilter.showFeature}
                    handleClear={() => {
                        setFeature([]);
                    }}
                >
                    <div className="flex flex-col gap-y-[12px]">
                        <KPLSelect
                            hasAllOption={true}
                            value={feature[0] || ALL_VALUE}
                            onKPLChange={(e: any) => {
                                if (e.title === ALL_VALUE) {
                                    setFeature([]);
                                    return;
                                }

                                setFeature([e.configKey]);
                            }}
                        />
                    </div>
                </FilterCard>
            </div>
        </div>
    );
};
