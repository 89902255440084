import { useEffect, useRef, useState } from 'react';
import { CSS_INPUT, MAX_TEXT_LENGTH } from '../../../constants';
import { useChatContext } from '../../../contexts';
import { CN } from '../../../utils';
import { useMobile } from '../../../hooks';

type TTextBox = {
    isError?: boolean;
    onSend: () => void;
    placeholder: string;
};

const TextBox = ({ onSend, placeholder, isError }: TTextBox) => {
    const isMobile = useMobile();
    const [hasBeenFocused, setHasBeenFocused] = useState(false);
    const { currentMessage, setCurrentMessage } = useChatContext();
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        textAreaRef?.current?.focus();
    }, [currentMessage]);

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setCurrentMessage(e.target.value);
    };

    const handleSubmit = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        e.preventDefault();
        setHasBeenFocused(false);
        onSend();
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            handleSubmit(e);
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter' && e.shiftKey) {
            e.preventDefault();
            const { selectionStart, selectionEnd, scrollTop, value } = e.currentTarget;
            const newValue = value.substring(0, selectionStart) + '\n' + value.substring(selectionEnd, value.length);
            const newCursorPosition = selectionStart + 1;
            setCurrentMessage(newValue);

            setTimeout(() => {
                if (textAreaRef?.current) {
                    textAreaRef.current.scrollTop = scrollTop;
                    textAreaRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
                }
            });
        }
    };

    return (
        <div className="my-2 w-full relative">
            <textarea
                id="chatbot-textarea"
                rows={3}
                ref={textAreaRef}
                onFocus={() => setHasBeenFocused(true)}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                onKeyPress={handleKeyPress}
                value={currentMessage}
                placeholder={placeholder}
                className={CN(`${CSS_INPUT} w-full`, {
                    'focus:ring-R-500 focus:border-R-500': hasBeenFocused && isError,
                })}
            />
            <div className="w-full mx-1 text-[10px] text-N-400 flex justify-between flex-row xs:flex-col absolute">
                {!isMobile && (
                    <span className="animate-fade animate-once animate-ease-in-out">
                        <kbd className="text-N-500">Shift + Enter </kbd> for new line
                    </span>
                )}
                <span className="animate-fade animate-once animate-ease-in-out">
                    {currentMessage?.length || 0}/{MAX_TEXT_LENGTH}
                </span>
            </div>
        </div>
    );
};

export { TextBox };
