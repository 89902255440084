import { Dropdown, Link, FilterCard, SearchInput } from '@kaya/kaya-ui-design-system-pb';
import { useState } from 'react';
import { KPLSelect, ModelSelect } from '../../../custom-select-components';
import { CategorySelect } from '../../../custom-select-components/CategorySelect.tsx';
import { ALL_VALUE, NOT_AVAILABLE_VALUE } from '../../../../../constants';

type IPromptFilter = {
    model: string | null;
    setModel: Function;
    kpl: string | null;
    setKpl: Function;
    category: string | null;
    setCategory: Function;
};

type IHideShowFilterType = {
    model: boolean;
    kpl: boolean;
    category: boolean;
};

export const PromptFilterContainer = ({ model, setModel, kpl, setKpl, category, setCategory }: IPromptFilter) => {
    const [manageFilter, setManageFilter] = useState<IHideShowFilterType>({
        model: true,
        kpl: true,
        category: true,
    });

    return (
        <div className="admin-panel__filter-section sm:w-full min-w-[296px] flex flex-col gap-y-6">
            <div className="min-h-[36px] flex items-center justify-between">
                <p className="text-[18px] font-600">Filter</p>
                <Dropdown
                    wrapperClassName="z-[9999]"
                    selectionType="multiple"
                    triggerAction="onClick"
                    header={{
                        type: 'title',
                        title: (
                            <Link
                                appearance="neutral"
                                iconBefore="ri-delete-bin-6-line"
                                onClick={() => {
                                    setManageFilter({
                                        model: false,
                                        kpl: false,
                                        category: false,
                                    });
                                }}
                            >
                                Clear all filters
                            </Link>
                        ),
                    }}
                    dropdownItems={[
                        {
                            id: 1,
                            label: 'Model',
                            onClick: id => {
                                setManageFilter({ ...manageFilter, model: !manageFilter.model });
                            },
                            defaultChecked: manageFilter.model,
                        },
                        {
                            id: 2,
                            label: 'KPL',
                            onClick: id => {
                                setManageFilter({ ...manageFilter, kpl: !manageFilter.kpl });
                            },
                            defaultChecked: manageFilter.kpl,
                        },
                        {
                            id: 3,
                            label: 'Category',
                            onClick: id => {
                                setManageFilter({ ...manageFilter, category: !manageFilter.category });
                            },
                            defaultChecked: manageFilter.category,
                        },
                    ]}
                >
                    <Link appearance="neutral" iconBefore="ri-equalizer-line" onClick={() => {}}>
                        Manage filters
                    </Link>
                </Dropdown>
            </div>
            <div className="admin-panel__filter-card-container flex flex-col border border-N-200 border-b-0">
                <FilterCard
                    title="Model"
                    showClear={model !== null ? true : false}
                    show={manageFilter?.model}
                    handleClear={() => setModel(null)}
                >
                    <div className="flex flex-col gap-y-[12px]">
                        <ModelSelect
                            hasAllOption={true}
                            hasEmptyOption={true}
                            value={model}
                            onChange={(e: any) => {
                                setModel(e.name === NOT_AVAILABLE_VALUE ? e.name : e.model);
                            }}
                        />
                    </div>
                </FilterCard>
                <FilterCard
                    title="KPL"
                    showClear={kpl !== null ? true : false}
                    show={manageFilter?.kpl}
                    handleClear={() => setKpl(null)}
                >
                    <div className="flex flex-col gap-y-[12px]">
                        <KPLSelect
                            hasAllOption={true}
                            hasEmptyOption={true}
                            value={kpl}
                            onKPLChange={(e: any) => {
                                setKpl(e.title === NOT_AVAILABLE_VALUE ? e.title : e.configKey);
                            }}
                        />
                    </div>
                </FilterCard>
                <FilterCard
                    title="Category"
                    showClear={category !== null ? true : false}
                    show={manageFilter?.category}
                    handleClear={() => setCategory(null)}
                >
                    <div className="flex flex-col gap-y-[12px]">
                        <CategorySelect
                            hasAllOption={true}
                            value={category}
                            onChange={(e: any) => {
                                setCategory(e.key);
                            }}
                        />
                    </div>
                </FilterCard>
            </div>
        </div>
    );
};
