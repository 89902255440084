import { Button, Drawer, Tooltip } from '@kaya/kaya-ui-design-system-pb';
import { useMobile } from '../../../../../hooks';
import { KPLForm } from '../kpl-form/KPLForm';
import { useKPLFormContainer } from './useKPLFormContainer';
import { useState } from 'react';

interface IKPLFormContainerProps {
    isNewKplModalOpen: boolean;
    setIsNewKplModalOpen: Function;
    selectedKPLKey?: string;
    refetchData?: Function | undefined;
}

export const KPLFormContainer = ({
    isNewKplModalOpen,
    setIsNewKplModalOpen,
    selectedKPLKey,
    refetchData,
}: IKPLFormContainerProps) => {
    const isMobile = useMobile();
    const { currentStep, setCurrentStep, isCurrentStepValid, handleSubmit, resetKPLFormData, validationErrorMsgs } =
        useKPLFormContainer(selectedKPLKey, isNewKplModalOpen, refetchData);
    const [isLoading, setIsLoading] = useState(false);

    const closeAndClearForm = () => {
        setIsNewKplModalOpen(false);
        resetKPLFormData();
        setCurrentStep({ step: 1, disabled: false });
    };

    const handleBack = () => {
        if (currentStep.step !== 1) {
            setCurrentStep(currentStep => ({
                step: currentStep.step - 1,
                disabled: false,
            }));
        } else {
            closeAndClearForm();
        }
    };

    const handleNext = async () => {
        if (currentStep.step === 3) {
            try {
                setIsLoading(true);
                await handleSubmit();
            } catch (error) {
                console.error('Error while saving KPL:', error);
            } finally {
                setIsLoading(false);
                closeAndClearForm();
            }
        } else {
            setCurrentStep(currentStep => ({
                step: currentStep.step + 1,
                disabled: false,
            }));
        }
    };

    return (
        <Drawer
            closableOnClickOutside={false}
            isOpen={isNewKplModalOpen}
            onClose={closeAndClearForm}
            title={selectedKPLKey ? 'Edit KPL' : 'Add KPL'}
            headerIcon="ri-clipboard-line"
            width={800}
            isHideClose={true}
            fullWidth={isMobile}
            className="z-[99]"
            footer={
                <div className="flex items-center justify-between gap-x-2">
                    {validationErrorMsgs?.length > 0 && (
                        <Tooltip
                            id={'validation-error-tooltip-user-section'}
                            tooltipType="detailed"
                            subText={
                                <ul>
                                    {validationErrorMsgs?.map((data: any, key: any) => (
                                        <li key={key} className="flex items-center justify-start gap-3">
                                            <span>
                                                <i className="ri-information-fill text-[18px]" />
                                            </span>
                                            <span>{data}</span>
                                        </li>
                                    ))}
                                </ul>
                            }
                            variant="dark"
                        >
                            <span className="w-full text-R-600 flex items-center text-xs sm:gap-x-0 gap-x-2">
                                <span> Ensure all fields are filled out correctly</span>{' '}
                                <i className="sm:hidden block ri-question-line" />
                            </span>
                        </Tooltip>
                    )}
                    <div className="flex items-center justify-end gap-x-2 flex-grow">
                        <Button appearance="primary" view="outline" size="medium" onClick={handleBack}>
                            {currentStep.step > 1 ? 'Back' : 'Cancel'}
                        </Button>
                        <Button
                            isDisabled={!isCurrentStepValid}
                            appearance="primary"
                            view="solid"
                            size="medium"
                            onClick={handleNext}
                            isLoading={isLoading}
                        >
                            {currentStep.step === 3 ? `${selectedKPLKey ? 'Edit KPL' : 'Create KPL'}` : 'Next'}
                        </Button>
                    </div>
                </div>
            }
        >
            <KPLForm currentStep={currentStep} setCurrentStep={setCurrentStep} />
        </Drawer>
    );
};
