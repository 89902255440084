import { Dropdown, FilterCard, Link, SearchInput } from '@kaya/kaya-ui-design-system-pb';
import { capitalize } from 'lodash';
import { useState } from 'react';
import { IAdminFilterContainerProps } from '../..';
import { ActiveStatus, ChatType } from '../../../../../__generated__/graphql';
import { ALL_VALUE, ALL_VALUE_CAPITALIZED, CHAT_TYPES, KPL_STATUSES } from '../../../../../constants';

type IHideShowFilterType = {
    showChatType: boolean;
    showStatus: boolean;
    showCreator: boolean;
};

export const KPLFilterContainer = ({ filterCriteria }: IAdminFilterContainerProps) => {
    const { chatType, setChatType, status, setStatus } = filterCriteria;
    const [manageFilter, setManageFilter] = useState<IHideShowFilterType>({
        showChatType: true,
        showStatus: true,
        showCreator: false,
    });

    return (
        <div className="admin-panel__filter-section sm:w-full  min-w-[296px] flex flex-col gap-y-6">
            <div className="min-h-[36px] flex items-center justify-between">
                <p className="text-[18px] font-600">Filter</p>
                <Dropdown
                    wrapperClassName="z-[9999]"
                    selectionType="multiple"
                    triggerAction="onClick"
                    header={{
                        type: 'title',
                        title: (
                            <Link
                                appearance="neutral"
                                iconBefore="ri-delete-bin-6-line"
                                onClick={() => {
                                    setManageFilter({
                                        showChatType: false,
                                        showStatus: false,
                                        showCreator: false,
                                    });
                                }}
                            >
                                Clear all filters
                            </Link>
                        ),
                    }}
                    dropdownItems={[
                        {
                            id: 1,
                            label: 'Chat Type',
                            onClick: id => {
                                setManageFilter({ ...manageFilter, showChatType: !manageFilter.showChatType });
                            },
                            defaultChecked: manageFilter.showChatType,
                        },
                        {
                            id: 2,
                            label: 'Status',
                            onClick: id => {
                                setManageFilter({ ...manageFilter, showStatus: !manageFilter.showStatus });
                            },
                            defaultChecked: manageFilter.showStatus,
                        },
                    ]}
                >
                    <Link appearance="neutral" iconBefore="ri-equalizer-line" onClick={() => {}}>
                        Manage filters
                    </Link>
                </Dropdown>
            </div>
            <div className="admin-panel__filter-card-container flex flex-col border border-N-200 border-b-0">
                <FilterCard
                    title="Chat type"
                    showClear={chatType !== ALL_VALUE_CAPITALIZED ? true : false}
                    show={manageFilter.showChatType}
                    handleClear={() => {
                        setChatType(ALL_VALUE_CAPITALIZED);
                    }}
                >
                    <div className="flex flex-col gap-y-[12px]">
                        <SearchInput
                            className="w-full"
                            value={capitalize(chatType.toLowerCase()) || ''}
                            view="default"
                            items={[ALL_VALUE, ...CHAT_TYPES.map(type => capitalize(type.toLowerCase()))]}
                            onChange={(value: ChatType) => setChatType(value.toUpperCase() as ChatType)}
                        />
                    </div>
                </FilterCard>
                <FilterCard
                    title="Status"
                    showClear={status !== ALL_VALUE_CAPITALIZED ? true : false}
                    show={manageFilter.showStatus}
                    handleClear={() => {
                        setStatus(ALL_VALUE_CAPITALIZED);
                    }}
                >
                    <div className="flex flex-col gap-y-[12px]">
                        <SearchInput
                            className="w-full"
                            value={capitalize(status.toLowerCase()) || ''}
                            view="default"
                            onChange={(value: ActiveStatus) => setStatus(value.toUpperCase() as ActiveStatus)}
                            items={[ALL_VALUE, ...KPL_STATUSES.map(status => capitalize(status.toLowerCase()))]}
                        />
                    </div>
                </FilterCard>
            </div>
        </div>
    );
};
