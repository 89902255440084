import { TextField } from '@kaya/kaya-ui-design-system-pb';
import { useState } from 'react';
import { AdminPanelModalActions, useAdminPanelContext } from '../../../../../../../contexts';
import { deConstantCase, extractVariables, toConstantCase } from '../../../../../../../utils';
import { CategorySelect } from '../../../../../custom-select-components/CategorySelect';
import { ExplanationTooltip } from '../../../../../KPL-management-container/common/explanation-tooltip';

type IPromptAdd = {
    formState: any;
};

export const PromptAddForm = ({ formState }: IPromptAdd) => {
    const {
        isLoading,
        promptName,
        setPromptName,
        promptTemplate,
        setPromptTemplate,
        variables,
        setVariables,
        category,
        setCategory,
    } = formState;
    const { selectedPromptAction } = useAdminPanelContext();
    const isEdit = selectedPromptAction !== AdminPanelModalActions?.CREATE ?? false;

    if (isLoading) return <div className="py-[16px] px-[24px] flex items-center justify-center h-full">Loading</div>;

    return (
        <div className="py-[16px] px-[24px] flex flex-col gap-y-5 items-start w-full">
            <TextField
                step="1"
                view="default"
                label="Prompt Name"
                wrapperClassName="w-full"
                placeholder="Prompt Name"
                onChange={(e: any) => setPromptName(toConstantCase(e?.target?.value))}
                type="text"
                autoComplete="nope"
                className="h-[45px] min-w-[216px]"
                defaultValue={isEdit ? deConstantCase(promptName) : ''}
                readOnly={selectedPromptAction === AdminPanelModalActions?.VIEW}
                required={true}
                disabled={isEdit}
            />
            <div className="flex flex-row justify-between gap-y-5 gap-x-5 w-full">
                <div className="flex flex-col gap-y-1 items-start w-full">
                    <p className="text-xs text-N-900 gap-x-1 font-500 flex items-center w-full cursor-pointer z-[999]">
                        Select Category
                        <ExplanationTooltip
                            id="icon-tooltip-category"
                            description={'Select the category of the prompt template'}
                        />
                    </p>
                    <div className="category-select">
                        <CategorySelect
                            value={category}
                            onChange={(e: any) => {
                                setCategory(e.key);
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-y-1 w-full items-end">
                <TextField
                    step="1"
                    view="default"
                    label="Prompt template"
                    wrapperClassName="w-full"
                    placeholder="Prompt template"
                    type="text"
                    autoComplete="nope"
                    className="min-h-[235px]"
                    rows={10}
                    onChange={(e: any) => {
                        setPromptTemplate(e?.target?.value);
                        setVariables(extractVariables(e?.target?.value));
                    }}
                    defaultValue={isEdit ? promptTemplate : ''}
                    readOnly={selectedPromptAction === AdminPanelModalActions?.VIEW}
                    required={true}
                />
            </div>
            <TextField
                step="1"
                view="default"
                label="Variables"
                type="text"
                wrapperClassName="w-full"
                autoComplete="nope"
                className="h-[45px]"
                value={variables.join(', ')}
                readOnly={true}
            />
        </div>
    );
};
