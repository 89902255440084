import { TextField, Tooltip } from '@kaya/kaya-ui-design-system-pb';
import React from 'react';
import { AdminPanelModalActions } from '../../../../../contexts';
import { useAdminTable, useMobile } from '../../../../../hooks';
import { CN, uniqueId } from '../../../../../utils';
import { UITableV2 } from '../../../table';
import { IAdminFilterContainerProps } from '../../KPLManagementContainer';
import { KPLFormContainer } from '../kpl-form-container';
import { KPLViewContainer } from '../kpl-view-container';
import './KPLTableContainer.scss';

export const KPLTableContainer = ({ filterCriteria }: IAdminFilterContainerProps) => {
    const {
        tableColumnDefinition,
        tableData,
        isKplViewModalOpen,
        setKPLViewModalOpen,
        selectedKPLAction,
        setSearchTerm,
        selectedKPLKey,
        count,
        scrollableRef,
        refetchData,
        loading,
        tableLoadingText,
        isLoadingMore,
        tableElementId,
    } = useAdminTable(filterCriteria);

    const isMobile = useMobile();

    return (
        <React.Fragment>
            <div className="admin-panel__table-section flex sm:w-full flex-col gap-y-4 w-full h-full">
                <div className="admin-panel__search flex sm:flex-col text-start sm:items-start items-center gap-x-20 justify-between">
                    <div className="flex gap-2">
                        <p className="text-N-600 whitespace-nowrap">
                            Showing <span className="text-N-900 font-600">{count}</span> KPLs
                        </p>
                        <Tooltip
                            id={uniqueId()}
                            title={'Reload KPLs'}
                            tooltipType="minimal"
                            variant="dark"
                            place="right"
                        >
                            <i
                                onClick={() => refetchData()}
                                className={CN(
                                    'ri-refresh-line text-sm font-500 text-N-900 cursor-pointer hover:text-[#1D5BD7] hover:font-bold',
                                    {
                                        'animate-spin': loading,
                                    }
                                )}
                            />
                        </Tooltip>
                    </div>
                    <TextField
                        view="default"
                        iconBefore="ri-search-line"
                        placeholder="Search KPL by name"
                        wrapperClassName="w-full mr-[10px]"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(event.target.value)}
                    />
                </div>
                <div className="admin-panel__table w-full">
                    <div
                        id={tableElementId}
                        ref={scrollableRef}
                        className={CN('w-full sm:overflow-x-scroll h-[calc(100vh-230px)]', {
                            'overflow-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2':
                                !isMobile,
                            'min-h-[400px]': !tableData?.length,
                        })}
                    >
                        <UITableV2
                            className="z-[0]"
                            isLoading={loading || isLoadingMore}
                            isLoadMoreTriggered={isLoadingMore}
                            loadingText={tableLoadingText}
                            width={100}
                            widthInPercentage={true}
                            tableData={tableData}
                            columnsArray={tableColumnDefinition}
                        />
                    </div>
                </div>
            </div>

            {/* KPL Detailed view modal */}
            {selectedKPLAction === AdminPanelModalActions.VIEW && selectedKPLKey && (
                <KPLViewContainer
                    isKplViewModalOpen={isKplViewModalOpen}
                    setKPLViewModalOpen={setKPLViewModalOpen}
                    selectedKPLKey={selectedKPLKey}
                />
            )}
            {/* KPL Edit Drawer */}
            {selectedKPLAction === AdminPanelModalActions.EDIT && selectedKPLKey && (
                <KPLFormContainer
                    setIsNewKplModalOpen={setKPLViewModalOpen}
                    isNewKplModalOpen={isKplViewModalOpen}
                    selectedKPLKey={selectedKPLKey}
                    refetchData={refetchData}
                />
            )}
        </React.Fragment>
    );
};
