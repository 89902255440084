import { gql } from '../__generated__';

export const CHAT_MESSAGES = gql(/* GraphQL */ `
    query GetMessages($input: GetMessageInput!) {
        getMessages(input: $input) {
            id
            replyTo
            content
            author
        }
    }
`);

export const GET_USER = gql(/* GraphQL */ `
    query GetParticipant($userId: ID!) {
        getParticipant(userId: $userId) {
            conversations {
                id
                title
                updatedAt
                threadId
                threadType
            }
        }
    }
`);

export const GET_SOURCE_CHUNKS = gql(/* GraphQL */ `
    query GetSourceChunks($sourceId: ID!) {
        getSourceChunks(sourceId: $sourceId)
    }
`);

export const GET_APPLICATION_CONFIGURATIONS = gql(/* GraphQL */ `
    query GetApplicationConfig($enabled: Boolean) {
        getApplicationConfig(enabled: $enabled) {
            configKey
            chatType
            description
            icon
            id
            enabled
            metadata
            title
        }
    }
`);

export const GET_APPLICATION_USER_CONFIG = gql(/* GraphQL */ `
    query GetApplicationUserConfig {
        getApplicationUserConfig {
            email
            features
            metadata
            models
            override
        }
    }
`);

export const GET_APPLICATION_USER_CONFIG_BY_EMAIL = gql(/* GraphQL */ `
    query GetApplicationUserConfigByEmail($email: String!) {
        getApplicationUserConfigByEmail(email: $email) {
            email
            features
            metadata
            models
            override
        }
    }
`);

export const GET_KPL_GUIDELINES = gql(/* GraphQL */ `
    query GetKplGuidelines($input: GetKplGuidelinesInput!) {
        getKplGuidelines(input: $input) {
            guidelinesText
        }
    }
`);

export const GER_JIRA_SETTINGS = gql(/* GraphQL */ `
    query GetJiraSettings($input: GetJiraSettingsInput!) {
        getJiraSettings(input: $input) {
            id
            key
            name
            sprints {
                id
                name
                originBoardId
                state
            }
        }
    }
`);

export const GET_LIVE_CUSTOMER_AGENT = gql(/* GraphQL */ `
    query GetLiveCustomerAgentDetails {
        getLiveCustomerAgentDetails {
            audioFileUrl
            transcript {
                message
                time
                type
            }
        }
    }
`);

export const GET_KPLS_PAGINATED = gql(/* GraphQL */ `
    query GetAllKPLConfigurations($input: GetAllKPLConfigurationsInput!) {
        getAllKPLConfigurations(input: $input) {
            kpls {
                id
                enabled
                configKey
                description
                chatType
                metadata
                title
                icon
            }
            page
            pageSize
            totalCount
            totalPages
        }
    }
`);

export const GET_KPL_BY_KEY = gql(/* GraphQL */ `
    query GetKPLByConfigKey($configKey: String!) {
        getKPLByConfigKey(configKey: $configKey) {
            id
            icon
            enabled
            description
            configKey
            chatType
            metadata
            title
            domains
            users
            guidelines {
                guidelinesText
            }
            prompts {
                model
                category
                prompt
                variables
            }
        }
    }
`);

export const GET_EXISTING_KPL_USERS = gql(/* GraphQL */ `
    query GetExistingKPLUsers {
        getExistingKPLUsers {
            domains
            users
        }
    }
`);

export const GET_PROMPT_TEMPLATES_BY_CRITERIA = gql(/* GraphQL */ `
    query GetPromptTemplateByCriteria($criteria: GetPromptTemplateByCriteriaInput!) {
        getPromptTemplateByCriteria(criteria: $criteria) {
            page
            pageSize
            promptTemplates {
                category
                id
                metadata
                templateContent
                templateKey
                variables
            }
            totalCount
            totalPages
        }
    }
`);

export const GET_USERS_PAGINATED = gql(/* GraphQL */ `
    query GetAllUsers($input: GetAllUserConfigurationsInput!) {
        getAllUsers(input: $input) {
            users {
                metadata
                lastName
                firstName
                features
                email
                models
                override
            }
            totalPages
            totalCount
            pageSize
            page
        }
    }
`);

export const GET_USER_BY_EMAIL = gql(/* GraphQL */ `
    query GetUserByUserName($email: String!) {
        getUserByUserName(email: $email) {
            firstName
            lastName
            email
            models
            features
            metadata
            override
        }
    }
`);
