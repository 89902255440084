import { gql } from '../__generated__';

export const ADD_MESSAGE = gql(/* GraphQL */ `
    mutation AddMessage($input: AddMessageInput!) {
        addMessage(input: $input) {
            id
        }
    }
`);

export const UPSERT_USER = gql(/* GraphQL */ `
    mutation UpsertParticipant($input: UpsertUserInput!) {
        upsertParticipant(input: $input) {
            id
        }
    }
`);

export const CREATE_TIMESHEET = gql(/* GraphQL */ `
    mutation AddActivityLog($input: AddActivityLogInput!) {
        addActivityLog(input: $input) {
            id
        }
    }
`);

export const APPROVE_TIMESHEET = gql(/* GraphQL */ `
    mutation ApproveActivityLog($input: AddApproveLogInput!) {
        approveActivityLog(input: $input) {
            id
        }
    }
`);

export const ADD_FEEDBACK = gql(/* GraphQL */ `
    mutation AddFeedback($input: AddFeedbackInput, $file: Upload) {
        addFeedback(input: $input, file: $file)
    }
`);

export const ADD_MESSAGE_FEEDBACK = gql(/* GraphQL */ `
    mutation AddMessageFeedback($input: AddMessageFeedbackInput!) {
        addMessageFeedback(input: $input) {
            id
        }
    }
`);

export const MARK_MESSAGES_AS_READ = gql(/* GraphQL */ `
    mutation MarkMessagesAsRead($input: MarkMessageAsReadInput!) {
        markMessagesAsRead(input: $input) {
            id
        }
    }
`);

export const UPDATE_CONVERSATION = gql(/* GraphQL */ `
    mutation UpdateConversation($input: UpdateConversationInput!) {
        updateConversation(input: $input) {
            id
        }
    }
`);

export const ADD_ACTION_ON_DISLIKE = gql(/* GraphQL */ `
    mutation ActionOnDislikedMessage($input: ActionOnDislikedMessageInput!) {
        actionOnDislikedMessage(input: $input)
    }
`);

export const GENERATE_REQUIREMENTS = gql(/* GraphQL */ `
    mutation GenerateRequirements($input: GenerateRequirementsInput) {
        generateRequirements(input: $input)
    }
`);

export const GENERATE_JIRA_TICKETS = gql(/* GraphQL */ `
    mutation GenerateJiraTickets($input: GenerateJiraTicketsInput!) {
        generateJiraTickets(input: $input)
    }
`);

export const GENERATE_CODE_FROM_REQUIREMENTS = gql(/* GraphQL */ `
    mutation GenerateCodeFromRequirements($input: GenerateCodeFromRequirementsInput!) {
        generateCodeFromRequirements(input: $input)
    }
`);

export const STOP_GENERATION = gql(/* GraphQL */ `
    mutation StopGeneration($input: StopGenerationInput!) {
        stopGeneration(input: $input)
    }
`);

export const SAVE_DOCUMENTS = gql(/* GraphQL */ `
    mutation saveNewDocuments($input: SaveNewDocumentsInput!) {
        saveNewDocuments(input: $input)
    }
`);

export const DELETE_DOCUMENTS = gql(/* GraphQL */ `
    mutation DeleteDocument($input: DeleteDocumentInput!) {
        deleteDocument(input: $input)
    }
`);

export const DELETE_TEMPORARY_DOCUMENTS = gql(/* GraphQL */ `
    mutation DeleteFaissIndex($input: DeleteIndexInput!) {
        deleteFaissIndex(input: $input)
    }
`);

export const GENERATE_SPRINT = gql(/* GraphQL */ `
    mutation GenerateSprintAnalysisReport($input: GenerateSprintAnalysisReportInput!) {
        generateSprintAnalysisReport(input: $input) {
            overview
            report
        }
    }
`);

export const UPLOAD_TIMESHEETS = gql(/* GraphQL */ `
    mutation UploadTimesheet($input: AddTimesheetInput!) {
        uploadTimesheet(input: $input)
    }
`);

export const SAVE_CALL_REPORTS = gql(/* GraphQL */ `
    mutation SaveNewCallReports($input: SaveNewDocumentsInput!) {
        saveNewCallReports(input: $input) {
            option
            question
            enabled
        }
    }
`);

export const ANALYZE_CALL_TRANSCRIPT = gql(/* GraphQL */ `
    mutation AnalyzeCallTranscript($input: AnalyzeCallTranscriptInput!) {
        analyzeCallTranscript(input: $input)
    }
`);

export const ANALYZE_TEST_CASES = gql(/* GraphQL */ `
    mutation AnalyzeTestCases($input: AnalyzeTestCasesInput!) {
        analyzeTestCases(input: $input)
    }
`);

export const ADD_FACT_CHECK_MESSAGE = gql(/* GraphQL */ `
    mutation AddFactCheckMessage($input: AddFactCheckMessageInput!) {
        addFactCheckMessage(input: $input) {
            id
            model
            conversationId
            content
            author
            type
            sources {
                id
                domainUrl
                sources
            }
        }
    }
`);

export const COMPARE_LLM_RESPONSES = gql(/* GraphQL */ `
    mutation CompareLLMResponses($input: LLMComparisonInput) {
        compareLLMResponses(input: $input)
    }
`);

export const GET_HIGHLIGHTED_PDF = gql(/* GraphQL */ `
    mutation GetHighlightedPDF($input: GetHighlightedPDFInput!) {
        getHighlightedPDF(input: $input) {
            page
            pdf
            source
        }
    }
`);

export const REMOVE_KPL = gql(/* GraphQL */ `
    mutation RemoveKPLConfiguration($configKey: String!) {
        removeKPLConfiguration(configKey: $configKey)
    }
`);

export const ADD_KPL = gql(/* GraphQL */ `
    mutation AddKPLConfiguration($input: KPLConfigurationInput!) {
        addKPLConfiguration(input: $input) {
            id
        }
    }
`);

export const UPDATE_KPL = gql(/* GraphQL */ `
    mutation UpdateKPLConfiguration($input: KPLConfigurationInput!) {
        updateKPLConfiguration(input: $input) {
            id
        }
    }
`);

export const CREATE_PROMPT_TEMPLATE = gql(/* GraphQL */ `
    mutation AddPromptTemplate($input: CreatePromptTemplateInput!) {
        addPromptTemplate(input: $input) {
            id
        }
    }
`);

export const UPDATE_PROMPT_TEMPLATE = gql(/* GraphQL */ `
    mutation UpdatePromptTemplate($input: UpdatePromptTemplateInput!) {
        updatePromptTemplate(input: $input) {
            id
        }
    }
`);
export const DELETE_PROMPT_TEMPLATE = gql(/* GraphQL */ `
    mutation DeletePromptTemplate($deletePromptTemplateId: Int!) {
        deletePromptTemplate(id: $deletePromptTemplateId)
    }
`);

export const UPDATE_USER = gql(/* GraphQL */ `
    mutation UpdateUser($input: UpdateUserInput!) {
        updateUser(input: $input)
    }
`);

export const ADD_NEW_USER = gql(/* GraphQL */ `
    mutation CreateNewUser($input: NewUserInput!) {
        createNewUser(input: $input)
    }
`);
