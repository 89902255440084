import { Button, Drawer, Tooltip } from '@kaya/kaya-ui-design-system-pb';
import { useAdminPanelContext } from '../../../contexts';
import { useMobile } from '../../../hooks';
import { UserForm } from '../user-form/UserForm';
import { useUserSubmission } from '../user-form/useUserSubmission';

interface IUserFormContainerProps {
    isUserFormDrawerOpen: boolean;
    setIsUserFormDrawerOpen: Function;
}

export const UserFormContainer = ({ isUserFormDrawerOpen, setIsUserFormDrawerOpen }: IUserFormContainerProps) => {
    const isMobile = useMobile();
    const { resetUserFormData } = useAdminPanelContext();
    const { handleSubmit, hasErrors, validationErrorMsgs } = useUserSubmission();

    return (
        <Drawer
            title={'Add New User'}
            headerIcon="ri-clipboard-line"
            closableOnClickOutside={true}
            fullWidth={isMobile}
            isHideClose={true}
            footer={
                <div className="flex items-center justify-between gap-x-2">
                    {validationErrorMsgs?.length > 0 && (
                        <Tooltip
                            id={'validation-error-tooltip-user-section'}
                            tooltipType="detailed"
                            subText={
                                <ul>
                                    {validationErrorMsgs?.map((data: any, key: any) => (
                                        <li key={key} className="flex items-center justify-start gap-3">
                                            <span>
                                                <i className="ri-information-fill text-[18px]" />
                                            </span>
                                            <span>{data.name}</span>
                                        </li>
                                    ))}
                                </ul>
                            }
                            variant="dark"
                        >
                            <span className="w-full text-R-600 flex items-center text-xs sm:gap-x-0 gap-x-2">
                                <span> Ensure all fields are filled out correctly</span>{' '}
                                <i className="sm:hidden block ri-question-line" />
                            </span>
                        </Tooltip>
                    )}

                    <div className="flex items-center justify-end gap-x-2 flex-grow">
                        <Button
                            appearance="primary"
                            size="medium"
                            view="outline"
                            onClick={() => {
                                setIsUserFormDrawerOpen(false);
                                resetUserFormData();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            isDisabled={hasErrors}
                            appearance="primary"
                            size="medium"
                            view="solid"
                            onClick={() => {
                                handleSubmit(true);
                                setIsUserFormDrawerOpen(false);
                            }}
                        >
                            Create
                        </Button>
                    </div>
                </div>
            }
            isOpen={isUserFormDrawerOpen}
            onClose={() => {
                setIsUserFormDrawerOpen(false);
                resetUserFormData();
            }}
            width={600}
        >
            <div className="p-5">
                <UserForm />
            </div>
        </Drawer>
    );
};
