import { FeatureType } from '../../../__generated__/graphql';
import { useChatContext } from '../../../contexts';
import { GenerateTechnicalSpecContent, TimesheetContent, LiveCustomerAgent, SprintAnalysisReport } from '../../pages';
import { WealthManagementTranscriptAnalysis } from '../wealth-management-transcript-analysis';

const CustomKPLComponent = () => {
    const { featureType } = useChatContext();

    switch (featureType) {
        case FeatureType.GenerateTechnicalSpec:
            return <GenerateTechnicalSpecContent />;
        case FeatureType.SprintAnalysisReport:
            return <SprintAnalysisReport />;
        case FeatureType.ClientKbSantander:
            return <LiveCustomerAgent />;
        case FeatureType.Timesheet:
            return <TimesheetContent />;
        case FeatureType.WealthManagementTranscriptAnalysis:
            return <WealthManagementTranscriptAnalysis />;
        default:
            <></>;
    }
};

export { CustomKPLComponent };
