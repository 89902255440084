import { useLazyQuery } from '@apollo/client';
import { Avatar, Button, CounterTooltip } from '@kaya/kaya-ui-design-system-pb';
import { useDebouncedValue } from '@mantine/hooks';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FeatureType } from '../__generated__/graphql';
import { columnsArrayType } from '../components/common/table';
import { IFilterUserCriteria } from '../components/common/user-management-container';
import { ALL_VALUE, ALL_VALUE_KPL, MAPPING_MODEL_TYPES, PAGE_SIZE, TABLE_MODEL_TYPES } from '../constants';
import { SelectedProfileProps, useAdminPanelContext } from '../contexts';
import { GET_USERS_PAGINATED } from '../graphql';
import { getInitials, uniqueId } from '../utils';
import { useAllKPLs } from './useAllKPLs';

export const useUserManageTable = (filterUserCriteria: IFilterUserCriteria) => {
    const [isProfileViewDrawerOpen, setIsProfileViewDrawerOpen] = useState<boolean>(false);
    const [isEditDrawerOpen, setIsEditDrawerOpen] = useState<boolean>(false);
    const { selectedProfile, setSelectedProfile, resetUserFormData } = useAdminPanelContext();
    const [tableData, setTableData] = useState<SelectedProfileProps[]>([]);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [queryPage, setQueryPage] = useState(1);
    const [dataRemaining, setDataRemaining] = useState(true);
    const scrollableRef = useRef<HTMLDivElement>(null);
    const [searchTerm, setSearchTerm] = useState<string>();
    const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 200);
    const [getAllUsersConfigurations, { loading, fetchMore, refetch }] = useLazyQuery(GET_USERS_PAGINATED);
    const tableElementId = 'kpl-listing-table-wrapper';
    const { ALL_KPL_SELECT_LIST } = useAllKPLs();
    const [previousScrollTop, setPreviousScrollTop] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [tableLoadingText, setTableLoadingText] = useState('Loading...');

    const refetchData = async () => {
        const { data } = await refetch();
        const prepareUserTableData = transformUserData(data?.getAllUsers?.users || []);
        resetTableData(prepareUserTableData || []);
    };

    const resetTableData = (tableData: SelectedProfileProps[]) => {
        setTableData(tableData);
        setDataRemaining(true);
        setQueryPage(1);
    };

    const transformUserData = (users: any[]): SelectedProfileProps[] => {
        return users.map((user: any) => {
            const hasAllKPLs = user.features.includes(ALL_VALUE_KPL);
            const currentKPLs = ALL_KPL_SELECT_LIST.filter(kpl => user.features.includes(kpl.configKey));
            const models = TABLE_MODEL_TYPES.filter(model =>
                user.models.includes(
                    Object.keys(MAPPING_MODEL_TYPES).find(key => MAPPING_MODEL_TYPES[key] === model.label)
                )
            );
            const hasAllModels = models.length === TABLE_MODEL_TYPES.length;

            return {
                name: `${user.firstName} ${user.lastName}`,
                email: user.email,
                avatarUrl: '',
                metadata: user.metadata,
                override: user.override,
                hasAllKPLs: hasAllKPLs,
                hasAllModels: hasAllModels,
                currentKPLs: currentKPLs,
                kpls: user.features.map((feature: string) => ({ name: feature })),
                models: models,
                kplTooltipData: hasAllKPLs
                    ? [
                          {
                              icon: <i className="ri-information-fill text-[18px]" />,
                              id: 0,
                              name: 'All KPLs are assigned',
                          },
                      ]
                    : currentKPLs.map((kpl: any, index: number) => ({
                          icon: <i className="ri-information-fill text-[18px]" />,
                          id: index,
                          name: kpl?.title,
                      })),
                modelTooltipData: models.map((model: any) => ({
                    icon: <i className="ri-information-fill text-[18px]" />,
                    id: model?.id,
                    name: model?.label,
                })),
            };
        });
    };

    //below function need to be removed on integration and tableData will be set through query
    const fetchTableData = () => {
        setTableLoadingText('Loading more Users...');
        getAllUsersConfigurations({
            variables: {
                input: {
                    page: 1,
                    pageSize: PAGE_SIZE,
                    keyword: debouncedSearchTerm,
                    featureTypes: filterUserCriteria.feature,
                    model: filterUserCriteria.model === ALL_VALUE ? null : filterUserCriteria.model,
                    userType: filterUserCriteria.userType === ALL_VALUE ? null : filterUserCriteria.userType,
                },
            },
            onCompleted: ({ getAllUsers }) => {
                setTotalPages(Number(getAllUsers?.totalPages));
                const transformedData = transformUserData(getAllUsers?.users || []);
                resetTableData(transformedData || []);
                setTableLoadingText('Loading...');
            },
        });
    };

    // Re fetch tableData query on filter changed
    useEffect(() => {
        //scroll to top when filter is applied
        scrollToTop();
        fetchTableData();
    }, [debouncedSearchTerm, filterUserCriteria]);

    const loadMoreData = async () => {
        setTableLoadingText('Loading more Users...');
        // If data is loading or isLoadingMore is true, return
        if (!tableData || loading || isLoadingMore) return;

        // Use the nextPage state variable to calculate the page
        const nextPage = queryPage + 1;

        if (totalPages >= nextPage) {
            // Set isLoadingMore to true
            setIsLoadingMore(true);
            await fetchMore({
                variables: {
                    input: {
                        page: nextPage,
                        pageSize: PAGE_SIZE,
                        keyword: debouncedSearchTerm,
                        featureTypes: filterUserCriteria.feature,
                        model: filterUserCriteria.model === ALL_VALUE ? null : filterUserCriteria.model,
                        userType: filterUserCriteria.userType === ALL_VALUE ? null : filterUserCriteria.userType,
                    },
                },
            }).then(({ data: newData }) => {
                if (Number(newData?.getAllUsers?.totalPages) === nextPage) {
                    setDataRemaining(false);
                }

                if (newData && newData.getAllUsers) {
                    const transformedData = transformUserData(newData?.getAllUsers?.users);
                    setTableData([...tableData, ...transformedData]);

                    // Update the queryPage state variable
                    setQueryPage(nextPage);
                }

                // Set isLoadingMore back to false
                setIsLoadingMore(false);
                setTableLoadingText('Loading...');
            });
        }
    };

    const handleScroll = useCallback(
        (e: Event) => {
            const target = e.target as HTMLDivElement;
            const { scrollTop, scrollHeight, clientHeight } = target;
            const isScrollingDown = scrollTop > previousScrollTop;
            setPreviousScrollTop(scrollTop);
            const scrollPercentage = scrollTop + clientHeight >= scrollHeight - 50;
            if (isScrollingDown && scrollPercentage && dataRemaining) {
                loadMoreData();
            }
        },
        [loadMoreData, dataRemaining]
    );

    useEffect(() => {
        const scrollableElement = scrollableRef.current;
        if (scrollableElement) {
            scrollableElement.addEventListener('scroll', handleScroll);
            return () => {
                scrollableElement.removeEventListener('scroll', handleScroll);
            };
        }
    }, [handleScroll, scrollableRef]);

    useEffect(() => {
        if (!isLoadingMore && tableData?.length) {
            heightLightLatestLoadedRow();
        }
    }, [isLoadingMore]);

    const heightLightLatestLoadedRow = () => {
        if (queryPage > 1) {
            const tableRows = document.querySelectorAll('table tbody tr');
            const newlyLoadedPageIndex = (queryPage - 1) * 25;

            for (let i = newlyLoadedPageIndex; i < tableData.length; i++) {
                tableRows[i].classList.add('blink-2');
            }
        }

        const listingTableWrapper = document.getElementById(tableElementId);

        if (listingTableWrapper) {
            const currentScrollPosition = listingTableWrapper.scrollTop;
            const newScrollPosition = currentScrollPosition + 250;
            const maxScroll = listingTableWrapper.scrollHeight - listingTableWrapper.clientHeight;
            const clampedScrollPosition = Math.min(maxScroll, Math.max(0, newScrollPosition));

            listingTableWrapper.scrollTo({
                top: clampedScrollPosition,
                behavior: 'smooth',
            });
        }
    };

    const scrollToTop = () => {
        try {
            const listingTableWrapper = document.getElementById(tableElementId);
            if (listingTableWrapper) {
                listingTableWrapper?.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            }
        } catch (error) {
            throw new Error('Prompts Table is not found');
        }
    };

    const tableColumnDefinition: columnsArrayType[] = useMemo(() => {
        return [
            {
                id: '1',
                Header: 'Name',
                showHeader: true,
                accessor: 'name',
                isSortable: true,
                width: 200,
                minWidth: 150,
                isCustomizable: false,
                Cell: (props: any) => {
                    const { row } = props || {};

                    return (
                        <div className="flex items-center gap-x-4">
                            <Avatar
                                imageURL={row?.original.avatarUrl}
                                className="cursor-pointer"
                                shape="circle"
                                size="md"
                                type={row?.original.avatarUrl ? 'image' : 'text'}
                                icon="ri-user-3-line"
                                textLabel={getInitials(row?.original.name)}
                            />
                            <div className="flex flex-col">
                                <p className="text-N-900 font-500">{row?.original.name}</p>
                                <p className="text-N-600 text-sm">{row?.original.email}</p>
                            </div>
                        </div>
                    );
                },
            },
            {
                id: '3',
                Header: 'KPLs',
                showHeader: true,
                accessor: 'kpls',
                isSortable: false,
                width: 200,
                minWidth: 200,
                alignment: 'left',
                isCustomizable: false,
                Cell: (props: any) => {
                    const { row } = props || {};
                    const hasAllKPLs = row?.original.hasAllKPLs || false;
                    const currentKPLs = row?.original.currentKPLs || [];
                    const toolTipData = row?.original.kplTooltipData || [];

                    return (
                        <CounterTooltip
                            count={hasAllKPLs ? 'All KPLs Enabled' : `${currentKPLs.length} KPLs Enabled`}
                            id={uniqueId()}
                            disabled={false}
                            toolTipData={toolTipData}
                        />
                    );
                },
            },
            {
                id: '4',
                Header: 'Models',
                showHeader: true,
                accessor: 'models',
                isSortable: false,
                width: 200,
                minWidth: 200,
                alignment: 'left',
                isCustomizable: false,
                Cell: (props: any) => {
                    const { row } = props;
                    const models = row?.original?.models;
                    const toolTipData = row?.original.modelTooltipData;
                    const hasAllModels = row?.original.hasAllModels;

                    return (
                        <CounterTooltip
                            count={hasAllModels ? 'All Models Enabled' : `${models.length} Models Enabled`}
                            id={uniqueId()}
                            disabled={false}
                            toolTipData={toolTipData}
                        />
                    );
                },
            },
            {
                id: '5',
                Header: 'Action',
                showHeader: true,
                accessor: 'action',
                isSortable: false,
                width: 100,
                minWidth: 100,
                isCustomizable: false,
                alignment: 'left',
                headerPadding: '12px 18px',
                Cell: (props: any) => {
                    const { row } = props || {};

                    return (
                        <div className="flex items-center justify-between">
                            <Button
                                view="ghost"
                                appearance="primary"
                                size="small"
                                onClick={() => {
                                    setSelectedProfile(row?.original);
                                    setIsProfileViewDrawerOpen(true);
                                }}
                            >
                                View
                            </Button>
                            <Button
                                view="ghost"
                                appearance="primary"
                                size="small"
                                className="ms-3"
                                onClick={() => {
                                    setSelectedProfile(row?.original);
                                    setIsEditDrawerOpen(true);
                                }}
                            >
                                Edit
                            </Button>
                        </div>
                    );
                },
            },
        ];
    }, []);

    return {
        tableColumnDefinition,
        tableData,
        searchTerm,
        setSearchTerm,
        refetchData,
        isProfileViewDrawerOpen,
        setIsProfileViewDrawerOpen,
        isEditDrawerOpen,
        setIsEditDrawerOpen,
        selectedProfile,
        scrollableRef,
        loading,
        tableElementId,
        resetUserFormData,
        isLoadingMore,
        tableLoadingText,
    };
};
