import { useLazyQuery, useMutation } from '@apollo/client';
import { Avatar, Button, Dropdown, Link, Tag } from '@kaya/kaya-ui-design-system-pb';
import { useDebouncedValue } from '@mantine/hooks';
import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { IFilterCriteria } from '../components/common/KPL-management-container';
import { columnsArrayType } from '../components/common/table';
import { ALL_VALUE_CAPITALIZED, PAGE_SIZE } from '../constants';
import { AdminPanelModalActions, useAdminPanelContext, useChatContext } from '../contexts';
import { GET_KPLS_PAGINATED, REMOVE_KPL } from '../graphql';
import { useMobile } from './useMobile';

export const useAdminTable = (filterCriteria: IFilterCriteria) => {
    // State variables
    const [isKplViewModalOpen, setKPLViewModalOpen] = useState<boolean>(false);
    const [selectedKPLKey, setSelectedKPLKey] = useState<string>();
    const [searchTerm, setSearchTerm] = useState<string>();
    const [queryPage, setQueryPage] = useState(1);
    const [tableData, setTableData] = useState<any[]>([]);
    const [dataRemaining, setDataRemaining] = useState(true);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [tableLoadingText, setTableLoadingText] = useState('Loading...');
    const { selectedKPLAction, setSelectedKPLAction } = useAdminPanelContext();
    const scrollableRef = useRef<HTMLDivElement>(null);
    const isMobile = useMobile();
    const [previousScrollTop, setPreviousScrollTop] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);

    // Constants
    const tableElementId = 'kpl-listing-table-wrapper';
    const count = tableData.length || 0;

    // Debounced search term
    const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 200);

    // Apollo GraphQL Hooks
    const [removeKPL] = useMutation(REMOVE_KPL);
    const [getAllKPLConfigurations, { loading, fetchMore, refetch }] = useLazyQuery(GET_KPLS_PAGINATED, {
        fetchPolicy: 'network-only',
    });
    const { refetchApplicationConfigs } = useChatContext();

    const changeKPLEnabledState = async (configKey: string) => {
        if (!configKey) return;

        try {
            await removeKPL({ variables: { configKey } });
            toast.success('KPL state changed successfully');
        } catch (error) {
            toast.error('Failed to change KPL state');
        } finally {
            setKPLViewModalOpen(false);
            refetchData();
            refetchApplicationConfigs();
        }
    };

    // Data fetching helpers
    const resetTableData = (tableData: any[]) => {
        setTableData(tableData);
        setDataRemaining(true);
        setQueryPage(1);
    };

    const refetchData = async () => {
        const { data } = await refetch();
        resetTableData(data?.getAllKPLConfigurations?.kpls || []);
    };

    const loadMoreData = async () => {
        setTableLoadingText('Loading more KPLs...');

        if (!tableData || loading || isLoadingMore) return;

        const nextPage = queryPage + 1;

        if (totalPages >= nextPage) {
            setIsLoadingMore(true);
            await fetchMore({
                variables: {
                    input: {
                        page: nextPage,
                        pageSize: PAGE_SIZE,
                        keyword: debouncedSearchTerm,
                        chatType: filterCriteria.chatType === ALL_VALUE_CAPITALIZED ? null : filterCriteria.chatType,
                        status: filterCriteria.status === ALL_VALUE_CAPITALIZED ? null : filterCriteria.status,
                    },
                },
            }).then(({ data: newData }) => {
                if (Number(newData?.getAllKPLConfigurations?.totalPages) === nextPage) {
                    setDataRemaining(false);
                }

                if (newData && newData.getAllKPLConfigurations) {
                    setTableData([...tableData, ...newData.getAllKPLConfigurations.kpls]);

                    setQueryPage(nextPage);
                }

                setIsLoadingMore(false);

                setTableLoadingText('Loading...');
            });
        }
    };

    const handleScroll = useCallback(
        (e: Event) => {
            const target = e.target as HTMLDivElement;
            const { scrollTop, scrollHeight, clientHeight } = target;
            const isScrollingDown = scrollTop > previousScrollTop;
            setPreviousScrollTop(scrollTop);
            const scrollPercentage = scrollTop + clientHeight >= scrollHeight - 50;
            if (isScrollingDown && scrollPercentage && dataRemaining) {
                loadMoreData();
            }
        },
        [loadMoreData, dataRemaining]
    );

    // Fetching initial data on load and search term change
    useEffect(() => {
        //scroll to top when filter is applied
        scrollToTop();
        const fetchAllKPLs = async () => {
            setTableLoadingText('Loading all KPLs...');
            const { data } = await getAllKPLConfigurations({
                variables: {
                    input: {
                        page: 1,
                        pageSize: PAGE_SIZE,
                        keyword: debouncedSearchTerm,
                        chatType: filterCriteria.chatType === ALL_VALUE_CAPITALIZED ? null : filterCriteria.chatType,
                        status: filterCriteria.status === ALL_VALUE_CAPITALIZED ? null : filterCriteria.status,
                    },
                },
            });
            resetTableData(data?.getAllKPLConfigurations?.kpls || []);
            setTotalPages(Number(data?.getAllKPLConfigurations?.totalPages));
            setTableLoadingText('Loading...');
        };

        fetchAllKPLs();
    }, [debouncedSearchTerm, filterCriteria, getAllKPLConfigurations]);

    // Highlight newly loaded rows and scroll to the latest
    useEffect(() => {
        if (!isLoadingMore && tableData?.length) {
            hightLightLatestLoadedRow();
        }
    }, [isLoadingMore]);

    const hightLightLatestLoadedRow = () => {
        if (queryPage > 1) {
            const tableRows = document.querySelectorAll('table tbody tr');
            const newlyLoadedPageIndex = (queryPage - 1) * 25;

            for (let i = newlyLoadedPageIndex; i < tableData.length; i++) {
                tableRows[i].classList.add('blink-2');
            }
        }

        const listingTableWrapper = document.getElementById(tableElementId);

        if (listingTableWrapper) {
            const currentScrollPosition = listingTableWrapper.scrollTop;
            const newScrollPosition = currentScrollPosition + 250;
            const maxScroll = listingTableWrapper.scrollHeight - listingTableWrapper.clientHeight;
            const clampedScrollPosition = Math.min(maxScroll, Math.max(0, newScrollPosition));

            listingTableWrapper.scrollTo({
                top: clampedScrollPosition,
                behavior: 'smooth',
            });
        }
    };

    // Scroll event listener
    useEffect(() => {
        const scrollableElement = scrollableRef.current;
        if (scrollableElement) {
            scrollableElement.addEventListener('scroll', handleScroll);
            return () => {
                scrollableElement.removeEventListener('scroll', handleScroll);
            };
        }
    }, [handleScroll, scrollableRef]);

    const scrollToTop = () => {
        try {
            const listingTableWrapper = document.getElementById(tableElementId);
            if (listingTableWrapper) {
                listingTableWrapper?.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            }
        } catch (error) {
            throw new Error('KPLs Table is not found');
        }
    };

    const tableColumnDefinition: columnsArrayType[] = [
        {
            id: '1',
            Header: 'Title',
            showHeader: true,
            accessor: 'title',
            isSortable: true,
            width: isMobile ? 250 : 200,
            minWidth: isMobile ? 250 : 200,
            isCustomizable: false,
            Cell: (props: any) => {
                const { row } = props || {};
                return (
                    <div className="flex items-center gap-x-2">
                        <div>
                            <Avatar shape="square" size="md" type="icon" icon={row?.original.icon} />
                        </div>
                        <div className="w-full">
                            <p className="text-N-900 font-500 text-sm">{row?.original.title}</p>
                        </div>
                    </div>
                );
            },
        },
        {
            id: '2',
            Header: 'Description',
            showHeader: true,
            accessor: 'description',
            isSortable: false,
            width: 200,
            minWidth: 200,
            isCustomizable: false,
            Cell: (props: any) => {
                const { row } = props || {};
                return <p className="max-w-[200px] truncate">{row?.original.description}</p>;
            },
        },
        {
            id: '3',
            Header: 'Chat Type',
            showHeader: true,
            accessor: 'chatType',
            isSortable: false,
            width: 100,
            minWidth: 100,
            isCustomizable: false,
            Cell: (props: any) => {
                const { row } = props || {};
                return <p>{row?.original.chatType}</p>;
            },
        },
        {
            id: '4',
            Header: 'Status',
            showHeader: true,
            accessor: 'status',
            isSortable: false,
            width: 50,
            minWidth: 50,
            isCustomizable: false,
            Cell: (props: any) => {
                const { row } = props || {};
                const label = Boolean(row?.original.enabled) ? 'Enabled' : 'Disabled';
                const appearance = Boolean(row?.original.enabled) ? 'green' : 'red';
                return <Tag label={label} appearance={appearance} size="xs" />;
            },
        },
        {
            id: '5',
            Header: 'Action',
            showHeader: true,
            accessor: 'title',
            isSortable: false,
            width: 141,
            minWidth: 141,
            isCustomizable: false,
            Cell: (props: any) => {
                const { row } = props || {};
                const label = Boolean(row?.original.enabled) ? 'Disable' : 'Enable';
                const className = Boolean(row?.original.enabled) ? '!text-R-500' : '!text-G-500';

                return (
                    <div className="flex items-center justify-between gap-x-4">
                        <Button
                            view="ghost"
                            appearance="primary"
                            size="small"
                            onClick={() => {
                                setSelectedKPLKey(row?.original?.configKey);
                                setSelectedKPLAction(AdminPanelModalActions.VIEW);
                                setKPLViewModalOpen(true);
                            }}
                        >
                            View KPL
                        </Button>
                        <Dropdown
                            wrapperClassName="z-[9999] right-0"
                            selectionType="single"
                            triggerAction="onClick"
                            dropdownItems={[
                                {
                                    id: 1,
                                    label: 'Edit',
                                    onClick: () => {
                                        setSelectedKPLKey(row?.original?.configKey);
                                        setSelectedKPLAction(AdminPanelModalActions.EDIT);
                                        setKPLViewModalOpen(true);
                                    },
                                },
                                {
                                    id: 2,
                                    label: label,
                                    className: className,
                                    onClick: () => {
                                        changeKPLEnabledState(row?.original?.configKey);
                                    },
                                },
                            ]}
                        >
                            <Link appearance="neutral" iconBefore="ri-more-2-line" onClick={() => {}} />
                        </Dropdown>
                    </div>
                );
            },
        },
    ];

    return {
        tableColumnDefinition,
        tableData,
        isKplViewModalOpen,
        setKPLViewModalOpen,
        selectedKPLAction,
        setSelectedKPLAction,
        selectedKPLKey,
        searchTerm,
        setSearchTerm,
        count,
        scrollableRef,
        refetchData,
        loading,
        isLoadingMore,
        tableLoadingText,
        tableElementId,
    };
};
