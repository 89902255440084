import jsPDF from 'jspdf';
import { markdownToTxt } from 'markdown-to-txt';
import { browserName, isMobile, isTablet, mobileVendor, osName } from 'react-device-detect';
import { toast } from 'react-toastify';
import showdown from 'showdown';
import { MessageAuthor } from '../__generated__/graphql';
import { IMG_KAYA_BOT_PNG, IMG_USER_PNG } from '../constants';
import { IChatbotUserDetails, IJiraOptions, IMessage } from '../contexts';
import { logger } from '../utils';

const getDeviceInformation = (): string => {
    const largeDeviceType = isTablet ? 'Tablet' : 'Desktop';
    const deviceType = isMobile ? 'Mobile' : largeDeviceType;
    return `${browserName} | ${osName} | ${deviceType}` + (isMobile || isTablet ? ` | ${mobileVendor}` : '');
};

const convertContentToHtml = (content: string): string => {
    const converter = new showdown.Converter();
    converter.setOption('tables', true);
    return converter.makeHtml(content);
};

const convertContentToText = (content: string): string => {
    return markdownToTxt(content);
};

const createHTMLFromContent = (
    content: string,
    title: string,
    customContent?: string,
    customStyles?: string
): string => {
    return /* HTML */ `
        <!doctype html>
        <html>
            <head>
                <title>${title}</title>
                <style>
                    @media print {
                        body {
                            font-size: 12pt;
                            color: #222;
                            margin: 10px;
                            padding-top: 10px;
                        }
                        .divider {
                            color: #000000;
                            margin: 10px 0;
                        }
                        table {
                            width: 100%;
                            border-collapse: collapse;
                        }
                        table th,
                        table td {
                            border: 1px solid #000;
                            padding: 8px;
                            text-align: left;
                        }
                        table th {
                            background-color: #f2f2f2;
                        }
                        .role-you {
                            color: #5158e2;
                            display: flex;
                            align-items: center;
                        }
                        .role-gpt {
                            color: #1e3b4e;
                            display: flex;
                            align-items: center;
                        }
                        .role-you img,
                        .role-gpt img {
                            border-radius: 50%;
                        }
                        ${customStyles ?? ''}
                    }
                </style>
            </head>
            <body>
                ${customContent ?? ''} ${content}
            </body>
        </html>
    `;
};

const saveHTMLAsPDF = (html: string): void => {
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    document.body.appendChild(iframe);

    iframe.onload = () => {
        try {
            iframe?.contentWindow?.print();
        } catch (error) {
            logger.error('Printing failed:', error);
        }
    };

    iframe?.contentDocument?.write(html);
    iframe?.contentDocument?.close();
};

const saveMarkdownAsPDF = (
    markdownContent: string,
    title: string,
    customContent?: string,
    customStyles?: string
): void => {
    const htmlContent = convertContentToHtml(markdownContent);
    const html = createHTMLFromContent(htmlContent, title, customContent, customStyles);
    saveHTMLAsPDF(html);
};

// Download as PDF Features

const downloadMessagesAsPDF = (
    messages: IMessage[],
    conversationId: string,
    userDetails: IChatbotUserDetails
): void => {
    const createMessageHtml = (message: IMessage, userImage: string, botImage: string): string => {
        const isUser = message.author === MessageAuthor.User;
        const roleClass = isUser ? 'role-you' : 'role-gpt';
        const imageSrc = isUser ? userImage : botImage;
        const author = isUser ? 'You' : 'Bernie';
        const content = convertContentToHtml(message.content);

        return /* HTML */ `
            <div class="${roleClass}">
                <img width="41" height="41" src="${imageSrc}" />
                &nbsp; ${author}:
            </div>
            ${content}
        `;
    };

    const createPrintableHtml = (messages: IMessage[], userImage: string, botImage: string, title: string): string => {
        const content = messages
            .map((message, index) => {
                const messageHtml = createMessageHtml(message, userImage, botImage);
                const divider = index % 2 === 1 ? '<hr class="divider" />' : '';
                return `${messageHtml} ${divider} <br />`;
            })
            .join('');

        return createHTMLFromContent(content, title);
    };

    const validateMessages = (messages: IMessage[]): boolean => {
        if (messages.length === 0) {
            toast.error('Please select a conversation with messages!');
            return false;
        }

        return true;
    };

    const saveAsPDFMobile = (messages: IMessage[], title: string): void => {
        const doc = new jsPDF();

        // Set Title with larger font and a specific color
        doc.setFontSize(12);
        doc.setTextColor(60, 142, 186); // Set color for the title (e.g., blue)
        doc.text(title, 5, 5);

        let yPos = 20;

        messages.forEach(message => {
            const authorText = message.author === MessageAuthor.User ? 'You' : 'Bernie';

            // Check for page end and add a new page if needed
            if (yPos > doc.internal.pageSize.height - 20) {
                doc.addPage();
                yPos = 10;
            }

            // Set Author Text with a different color
            message.author === MessageAuthor.User ? doc.setTextColor(220, 50, 50) : doc.setTextColor(0, 128, 0);
            doc.text(`${authorText}:`, 10, yPos);

            // Reset text color to black for the message content
            doc.setTextColor(0, 0, 0);

            // Add Message Content with text wrapping
            const content = convertContentToText(message.content);
            const lines = doc.splitTextToSize(content, 180); // 180 is the width in mm
            doc.text(lines, 10, yPos + 10);

            // Update Y position for next message
            yPos += lines.length * 7 + 10; // Adjust line height as needed
        });

        doc.save(`bernie-conversation-${Date.now()}.pdf`);
    };

    try {
        if (validateMessages(messages)) {
            const title = `${userDetails.name} - ${conversationId} | ${getDeviceInformation()}`;

            if (isMobile) {
                saveAsPDFMobile(messages, title);
            } else {
                const userImage = userDetails.imageUrl || IMG_USER_PNG;
                const botImage = IMG_KAYA_BOT_PNG;
                const html = createPrintableHtml(messages, userImage, botImage, title);
                saveHTMLAsPDF(html);
            }
        }
    } catch (error) {
        logger.error('PDF generation failed:', error);
        toast.error('Cannot generate PDF!');
    }
};

const downloadCallReportAsPDF = (callReport: string): void => {
    const saveAsPDFMobile = (callReport: string, title: string) => {
        const doc = new jsPDF();

        // Set Title with larger font and a specific color
        doc.setFontSize(16);
        doc.setTextColor(60, 142, 186); // Set color for the title (e.g., blue)
        doc.text(title, 10, 10);

        let yPos = 30; // Initial position for content

        // Reset for content
        doc.setFontSize(12);
        doc.setTextColor(0, 0, 0);

        // Set Content with text wrapping
        const textContent = convertContentToText(callReport);
        const lines = doc.splitTextToSize(textContent, 180); // 180 is the width in mm

        lines.forEach((line: any) => {
            // Check for page end and add a new page if needed
            if (yPos > doc.internal.pageSize.height - 20) {
                doc.addPage();
                yPos = 10;
            }

            doc.text(line, 10, yPos);
            yPos += 10; // Adjust line height as needed
        });

        doc.save(`call-report-${Date.now()}.pdf`);
    };

    try {
        const title = 'Detailed Citi Insights Call Report Summary';

        if (isMobile) {
            saveAsPDFMobile(callReport, title);
        } else {
            saveMarkdownAsPDF(
                callReport,
                title,
                /* HTML */ `
                    <h1>Call Report Analysis</h1>
                    <p class="description">
                        This report contains detailed call reports and the analysis made during the specified period.
                    </p>
                `,
                /* CSS */ `
                        h1 {
                            text-align: center;
                            margin-bottom: 20px;
                        }
                        .description {
                            text-align: center;
                            font-style: italic;
                            color: #777;
                            margin-bottom: 20px;
                        }
                    `
            );
        }
    } catch (error) {
        logger.error('PDF generation failed:', error);
        toast.error('Cannot generate PDF!');
    }
};

const downloadSprintAnalysisAsPDF = (report: string, jiraOptions: IJiraOptions): void => {
    const saveAsPDFMobile = (report: string, title: string) => {
        const doc = new jsPDF();

        // Set Title with larger font and a specific color
        doc.setFontSize(16);
        doc.setTextColor(60, 142, 186); // Set color for the title (e.g., blue)
        doc.text(title, 10, 10);

        let yPos = 30; // Initial position for content

        // Reset for content
        doc.setFontSize(12);
        doc.setTextColor(0, 0, 0);

        // Set Content with text wrapping
        const textContent = convertContentToText(report);
        const lines = doc.splitTextToSize(textContent, 180); // 180 is the width in mm

        lines.forEach((line: any) => {
            // Check for page end and add a new page if needed
            if (yPos > doc.internal.pageSize.height - 20) {
                doc.addPage();
                yPos = 10;
            }

            doc.text(line, 10, yPos);
            yPos += 10; // Adjust line height as needed
        });

        doc.save(`sprint-analysis-${Date.now()}.pdf`);
    };

    try {
        const title = `Detailed Sprint Analysis Report Summary | ${jiraOptions.jiraUserEmail} | ${jiraOptions.jiraInstanceName}`;

        if (isMobile) {
            saveAsPDFMobile(report, title);
        } else {
            saveMarkdownAsPDF(report, title);
        }
    } catch (error) {
        logger.error('PDF generation failed:', error);
        toast.error('Cannot generate PDF!');
    }
};

const downloadCallTranscriptAnalysisAsPDF = (report: string): void => {
    const saveAsPDFMobile = (report: string, title: string) => {
        const doc = new jsPDF();

        // Set Title with larger font and a specific color
        doc.setFontSize(16);
        doc.setTextColor(60, 142, 186); // Set color for the title (e.g., blue)
        doc.text(title, 10, 10);

        let yPos = 30; // Initial position for content

        // Reset for content
        doc.setFontSize(12);
        doc.setTextColor(0, 0, 0);

        // Set Content with text wrapping
        const textContent = convertContentToText(report);
        const lines = doc.splitTextToSize(textContent, 180); // 180 is the width in mm

        lines.forEach((line: any) => {
            // Check for page end and add a new page if needed
            if (yPos > doc.internal.pageSize.height - 20) {
                doc.addPage();
                yPos = 10;
            }

            doc.text(line, 10, yPos);
            yPos += 10; // Adjust line height as needed
        });

        doc.save(`call-transcript-${Date.now()}.pdf`);
    };

    try {
        const title = 'Detailed Support Call Transcript Summary';

        if (isMobile) {
            saveAsPDFMobile(report, title);
        } else {
            saveMarkdownAsPDF(report, title);
        }
    } catch (error) {
        logger.error('PDF generation failed:', error);
        toast.error('Cannot generate PDF!');
    }
};

export {
    downloadCallTranscriptAnalysisAsPDF,
    downloadCallReportAsPDF,
    downloadMessagesAsPDF,
    downloadSprintAnalysisAsPDF,
};
