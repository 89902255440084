import React, { createContext, ReactNode, useContext, useState } from 'react';
import { ChatType, OperationType, UserType } from '../__generated__/graphql';
import { CUSTOM_META_DATA_KEYS, MODEL_BASED_PROMPTS, SETTING_TYPES, TABLE_MODEL_TYPES } from '../constants';

export enum AdminPanelModalActions {
    VIEW = 'View',
    EDIT = 'Edit',
    DELETE = 'Delete',
    CREATE = 'Create',
    SAVE = 'Save',
}

export interface UserMetadata {
    default: {
        model: string;
        feature: string;
        webSearch: boolean;
        dynamicPrompts: boolean;
        noLLM: boolean;
    };
    userType: string;
}

export interface UserFormData {
    email: string;
    firstName: string;
    lastName: string;
    override: boolean;
    password: string;
    isTemporaryPass: boolean;
    models: any[];
    features: any[];
    metadata: UserMetadata;
    isPasswordUpdate?: boolean;
}

export interface SelectedProfileProps {
    name: string;
    email: string;
    avatarUrl: string;
    metadata: {
        default: {
            model: string;
            feature: string;
            webSearch: boolean;
            dynamicPrompts: boolean;
            noLLM: boolean;
        };
        userType: string;
    };
    hasAllKPLs: boolean;
    currentKPLs: {
        title: string;
        configKey: string;
    }[];
    kpls: any[];
    models: typeof TABLE_MODEL_TYPES;
    override: boolean;
    kplTooltipData?: any;
    modelTooltipData?: any;
}

const initialUserData: SelectedProfileProps = {
    name: '',
    email: '',
    avatarUrl: '',
    metadata: {
        default: {
            model: '',
            feature: '',
            webSearch: false,
            dynamicPrompts: false,
            noLLM: false,
        },
        userType: '',
    },
    hasAllKPLs: false,
    currentKPLs: [],
    override: false,
    kpls: [],
    models: [],
};

const initialUserFormData: UserFormData = {
    firstName: '',
    lastName: '',
    email: '',
    override: false,
    password: '',
    isTemporaryPass: false,
    models: [],
    features: [],
    metadata: {
        default: {
            model: '',
            feature: '',
            webSearch: false,
            dynamicPrompts: false,
            noLLM: false,
        },
        userType: UserType.User,
    },
};

export interface KPLFormData {
    title: string;
    icon: string;
    chatType: ChatType | null;
    shortDescription: string;
    detailedDescription: string;
    additionalSettings: Record<string, boolean>;
    autoGenerate: boolean;
    predefinedQuestions: string[];
    criteria: Record<string, string | null>;
    operation: OperationType;
    prompts: typeof MODEL_BASED_PROMPTS;
    users: string[];
    domains: string[];
    enabled: boolean;
}

const initialKPLFormData: KPLFormData = {
    title: '',
    icon: 'ri-information-line',
    chatType: ChatType.Assistant,
    shortDescription: '',
    detailedDescription: '',
    autoGenerate: false,
    additionalSettings: SETTING_TYPES.reduce(
        (acc, { key }) => {
            acc[key] = false;
            return acc;
        },
        {} as Record<string, boolean>
    ),
    predefinedQuestions: [''],
    criteria: CUSTOM_META_DATA_KEYS.reduce((acc, key) => ({ ...acc, [key]: null }), {}),
    operation: OperationType.Or,
    prompts: MODEL_BASED_PROMPTS,
    users: [],
    domains: [],
    enabled: false,
};

interface IAdminPanelContext {
    setSelectedPromptAction: Function;
    selectedPromptAction: AdminPanelModalActions | null;
    selectedKPLAction: AdminPanelModalActions | null;
    setSelectedKPLAction: React.Dispatch<React.SetStateAction<AdminPanelModalActions | null>>;
    selectedUserAction: AdminPanelModalActions | null;
    setSelectedUserAction: React.Dispatch<React.SetStateAction<AdminPanelModalActions | null>>;
    kplFormData: KPLFormData;
    setKPLFormData: React.Dispatch<React.SetStateAction<KPLFormData>>;
    resetKPLFormData: () => void;
    promptAction: string;
    setPromptAction: React.Dispatch<React.SetStateAction<string>>;
    userFormData: UserFormData;
    setUserFormData: React.Dispatch<React.SetStateAction<UserFormData>>;
    resetUserFormData: () => void;
    selectedProfile: SelectedProfileProps;
    setSelectedProfile: React.Dispatch<React.SetStateAction<SelectedProfileProps>>;
}

interface IAdminPanelContextProvider {
    children: ReactNode;
}

const AdminPanelContext = createContext<IAdminPanelContext>({
    selectedPromptAction: null,
    setSelectedPromptAction: () => {},
    selectedKPLAction: null,
    setSelectedKPLAction: () => {},
    selectedUserAction: null,
    setSelectedUserAction: () => {},
    promptAction: '',
    setPromptAction: () => {},
    kplFormData: initialKPLFormData,
    setKPLFormData: () => {},
    resetKPLFormData: () => {},
    userFormData: initialUserFormData,
    setUserFormData: () => {},
    resetUserFormData: () => {},
    selectedProfile: initialUserData,
    setSelectedProfile: () => {},
});

export const AdminPanelContextProvider: React.FC<IAdminPanelContextProvider> = ({ children }) => {
    const [selectedPromptAction, setSelectedPromptAction] = useState<AdminPanelModalActions | null>(null);
    const [selectedKPLAction, setSelectedKPLAction] = useState<AdminPanelModalActions | null>(null);
    const [selectedUserAction, setSelectedUserAction] = useState<AdminPanelModalActions | null>(null);
    const [promptAction, setPromptAction] = useState<string>('');
    const [kplFormData, setKPLFormData] = useState<KPLFormData>(initialKPLFormData);
    const [userFormData, setUserFormData] = useState<UserFormData>(initialUserFormData);
    const [selectedProfile, setSelectedProfile] = useState<SelectedProfileProps>(initialUserData);

    const resetKPLFormData = () => setKPLFormData(initialKPLFormData);
    const resetUserFormData = () => setUserFormData(initialUserFormData);

    return (
        <AdminPanelContext.Provider
            value={{
                setSelectedPromptAction,
                selectedPromptAction,
                selectedKPLAction,
                setSelectedKPLAction,
                selectedUserAction,
                setSelectedUserAction,
                promptAction,
                setPromptAction,
                kplFormData,
                setKPLFormData,
                resetKPLFormData,
                userFormData,
                setUserFormData,
                resetUserFormData,
                selectedProfile,
                setSelectedProfile,
            }}
        >
            {children}
        </AdminPanelContext.Provider>
    );
};

export const useAdminPanelContext = (): IAdminPanelContext => {
    const context = useContext(AdminPanelContext);

    if (!context) {
        throw new Error('useAdminPanelContext must be used within a AdminPanelContextProvider');
    }

    return context;
};
