import classMerge from 'classnames';
import { logger } from './logger';
import { FeedbackData } from '../contexts';

export const uniqueId = () => {
    return Date.now().toString(36) + Math.random().toString(36).substr(2, 5);
};

export const CN = (...args: any) => {
    return classMerge(...args);
};

export const getInitials = (fullName: string): string => {
    try {
        const [firstName, lastName] = fullName.split(' ');
        const firstInitial = firstName?.charAt(0) || '';
        const lastInitial = lastName?.charAt(0) || '';
        return firstInitial === lastInitial ? `${firstInitial}` : `${firstInitial}${lastInitial}`;
    } catch {
        return fullName;
    }
};

export function repeat(emoji: string, count: number) {
    let result = '';

    for (let i = 0; i < count; i++) {
        result += emoji;
    }

    return result;
}

export function generateStars(rating: number, totalStars: number) {
    const filledStars = '★'.repeat(rating);
    const emptyStars = '☆'.repeat(totalStars - rating);
    return `${filledStars}${emptyStars}`;
}

export function getRatingField(index: number): keyof FeedbackData {
    switch (index) {
        case 0:
            return 'technicalAssistance';
        case 1:
            return 'employeeEngagement';
        case 2:
            return 'dailyUsage';
        default:
            return 'technicalAssistance';
    }
}

export const decodeFromBase64 = (encodedText: string) => {
    try {
        return atob(encodedText);
    } catch (error) {
        logger.error('Error decoding from Base64:', error);
        return '';
    }
};

export function truncate(text: string, minLength: number = 50): string {
    return text.length > minLength ? text.slice(0, minLength) : text;
}

export function truncateWithEllipsis(text: string, maxLength: number = 50): string {
    return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
}
