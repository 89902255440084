import { TextField } from '@kaya/kaya-ui-design-system-pb';
import { IPromptFilter, useMobile, usePromptTable } from '../../../../../hooks';
import { UITableV2 } from '../../../table';
import { PromptFormContainer } from '../prompt-form-container/PromptFormContainer';
import { useCallback } from 'react';
import { debounce } from 'lodash';
import { CN } from '../../../../../utils';

export const PromptTableContainer = ({ model, kpl, category, search, setSearch, count, setCount }: IPromptFilter) => {
    const isMobile = useMobile();
    const {
        tableColumnDefinition,
        tableData,
        isPromptViewModalOpen,
        setPromptViewModalOpen,
        selectedPrompt,
        scrollableRef,
        tableElementId,
        loading,
        isLoadingMore,
        tableLoadingText,
        fetchTableData,
    } = usePromptTable({
        model,
        kpl,
        category,
        search,
        setCount,
    });

    const setDebounceSearch = useCallback(
        debounce((value: string) => {
            setSearch && setSearch(value);
        }, 500),
        []
    );

    return (
        <>
            <div className="admin-panel__table-section flex sm:w-full flex-col gap-y-4 overflow-auto w-full h-full">
                <div className="admin-panel__search flex sm:flex-col text-start sm:items-start items-center gap-x-20 justify-between">
                    <p className="text-N-600 whitespace-nowrap">
                        Showing <span className="text-N-900 font-600">{count}</span> Prompts
                    </p>
                    <TextField
                        view="default"
                        iconBefore="ri-search-line"
                        placeholder="Search Prompt by name"
                        wrapperClassName="w-full mr-[10px]"
                        onChange={(e: any) => {
                            setDebounceSearch(e.target.value);
                        }}
                    />
                </div>
                <div className="admin-panel__table w-full  h-full">
                    <div
                        id={tableElementId}
                        className={CN('w-full sm:overflow-x-scroll max-h-[calc(100vh-220px)]', {
                            ' overflow-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2  h-full':
                                !isMobile,
                            'min-h-[400px]': !tableData?.length,
                        })}
                        ref={scrollableRef}
                    >
                        <UITableV2
                            className="z-[0]"
                            loadingText={tableLoadingText}
                            isLoading={loading || isLoadingMore}
                            isLoadMoreTriggered={isLoadingMore}
                            width={100}
                            widthInPercentage={true}
                            tableData={tableData}
                            columnsArray={tableColumnDefinition}
                        />
                    </div>
                </div>
            </div>

            <PromptFormContainer
                isPromptViewModalOpen={isPromptViewModalOpen}
                setPromptViewModalOpen={setPromptViewModalOpen}
                selectedPrompt={selectedPrompt}
                fetchTableData={fetchTableData}
            />
        </>
    );
};
