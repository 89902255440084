import { useEffect, useState } from 'react';
import { AdminPanelModalActions, ModelListType, useAdminPanelContext } from '../contexts';
import { IPrompt } from './usePromptTable.tsx';
import { useMutation } from '@apollo/client';
import { CREATE_PROMPT_TEMPLATE, DELETE_PROMPT_TEMPLATE, UPDATE_PROMPT_TEMPLATE } from '../graphql';
import { toast } from 'react-toastify';
import { PromptTemplateCategory } from '../__generated__/graphql.ts';

export const usePromptForm = (selectedPrompt?: IPrompt, modelTypes: ModelListType[] = []) => {
    const { selectedPromptAction } = useAdminPanelContext();
    const [selectedModel, setSelectedModel] = useState<ModelListType>();
    const [kpl, setKpl] = useState<any>();
    const [category, setCategory] = useState<PromptTemplateCategory>(PromptTemplateCategory.ResponsePrompt);
    const [promptName, setPromptName] = useState<string>('');
    const [promptTemplate, setPromptTemplate] = useState<string>('');
    const [variables, setVariables] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [id, setId] = useState<number | undefined>(undefined);
    const [validated, setValidated] = useState(false);

    const [createPromptTemplate] = useMutation(CREATE_PROMPT_TEMPLATE);
    const [updatePromptTemplate] = useMutation(UPDATE_PROMPT_TEMPLATE);
    const [deletePromptTemplate] = useMutation(DELETE_PROMPT_TEMPLATE);
    const [validationErrorMsgs, setValidationErrorMsgs] = useState<any>();

    const initializeFormState = () => {
        if (selectedPromptAction && selectedPromptAction !== AdminPanelModalActions?.CREATE) {
            if (selectedPrompt) {
                setSelectedModel(
                    modelTypes.find(modelType => modelType.model.toString() == selectedPrompt?.metadata?.model)
                );
                setPromptName(selectedPrompt?.templateKey);
                setPromptTemplate(selectedPrompt?.templateContent);
                setVariables(selectedPrompt?.variables);
                setId(selectedPrompt?.id);
                setKpl(selectedPrompt?.metadata?.kpl);
                setCategory(selectedPrompt?.category as PromptTemplateCategory);
            }
        }
    };
    useEffect(() => {
        if (selectedPromptAction && selectedPromptAction !== AdminPanelModalActions?.CREATE) {
            if (selectedPrompt) {
                setSelectedModel(
                    modelTypes.find(modelType => modelType.model.toString() == selectedPrompt?.metadata?.model)
                );
                setPromptName(selectedPrompt?.templateKey);
                setPromptTemplate(selectedPrompt?.templateContent);
                setVariables(selectedPrompt?.variables);
                setId(selectedPrompt?.id);
                setKpl(selectedPrompt?.metadata?.kpl);
                setCategory(selectedPrompt?.category as PromptTemplateCategory);
            }
        }
    }, [selectedPromptAction]);

    const handleSaveAndUpdatePrompt = async () => {
        if (selectedPromptAction === AdminPanelModalActions?.EDIT) {
            if (id) {
                await updatePromptTemplate({
                    variables: {
                        input: {
                            id: id,
                            templateKey: promptName,
                            template: promptTemplate,
                            variables: variables,
                            model: selectedModel?.model,
                            kpl: kpl?.configKey,
                            category: category,
                        },
                    },
                });
                toast.success('Prompt template updated!');
            }
        } else if (selectedPromptAction === AdminPanelModalActions?.CREATE) {
            await createPromptTemplate({
                variables: {
                    input: {
                        templateKey: promptName,
                        template: promptTemplate,
                        variables: variables,
                        model: selectedModel?.model,
                        category: category,
                        kpl: kpl?.configKey,
                        isDeletable: true,
                    },
                },
                onCompleted: ({ addPromptTemplate }: any) => {
                    if (addPromptTemplate === null) {
                        throw new Error('Failed to create prompt template');
                    }
                    setId(addPromptTemplate.id);
                },
            });
            toast.success('Prompt template created!');
        }

        clearState();
    };

    const clearState = () => {
        setSelectedModel(undefined);
        setKpl(undefined);
        setCategory(PromptTemplateCategory.ResponsePrompt);
        setPromptName('');
        setPromptTemplate('');
        setVariables([]);
        setId(undefined);
    };

    useEffect(() => {
        let errorMessages: string[] = [];

        if (!promptName || promptName.trim() === '') {
            errorMessages.push('Prompt name is required.');
        }

        if (!promptTemplate || promptTemplate.trim() === '') {
            errorMessages.push('Prompt template is required.');
        }

        setValidationErrorMsgs(errorMessages);
        setValidated(errorMessages.length === 0);
    }, [promptName, promptTemplate]);

    const handleDeletePrompt = async () => {
        if (id) {
            await deletePromptTemplate({
                variables: {
                    deletePromptTemplateId: id,
                },
            });
            toast.success('Prompt template deleted!');
        }
    };

    return {
        handleSaveAndUpdatePrompt,
        handleDeletePrompt,
        clearState,
        setSelectedModel,
        selectedModel,
        isLoading,
        setPromptName,
        promptName,
        setPromptTemplate,
        promptTemplate,
        setVariables,
        variables,
        kpl,
        setKpl,
        category,
        setCategory,
        validated,
        setValidated,
        refetch: initializeFormState,
        validationErrorMsgs
    };
};
