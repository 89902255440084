import {
    AdminPanelSectionSelectionTypes,
    SideBarButtonSelectionTypes,
    useChatContext,
    useSideBarContext,
} from '../../../../contexts';
import { CN, uniqueId } from '../../../../utils';
import { ButtonWithTooltip, SideBarButton } from '../../buttons';
import { useMobile } from '../../../../hooks';

export const SidebarPrimaryBtnSection = () => {
    const { handleNewChat } = useChatContext();
    const { handleMobileMenuIconClick, setIsCollapsed, setAdminPanelSelection, isMenuCollapsed, isCollapsed } =
        useSideBarContext();
    const isMobile = useMobile();

    return (
        <div
            className={CN('sidebar_primary_section flex flex-col gap-y-6 px-3 py-1', {
                'pt-4 !gap-y-4 px-[24px]': isMobile,
            })}
        >
            <ButtonWithTooltip title="Start a New Chat" showTooltip={!isMenuCollapsed && !isMobile}>
                <button
                    onClick={() => {
                        handleNewChat();
                        handleMobileMenuIconClick();
                        setIsCollapsed(true);
                        setAdminPanelSelection(AdminPanelSectionSelectionTypes.NOT_SELECTED);
                    }}
                    className={CN(
                        'flex items-center justify-center gap-x-2 py-3 px-4 border-[0.5px] border-N-200 rounded-[2px] shadow appearance-none focus:outline focus:outline-offset-2 focus:outline-1 focus:outline-B-700 active:outline-B-700  hover:border-N-300 ease-in-out duration-75 delay-400',
                        {
                            'h-[40px] w-[40px] rounded-[8px] bg-B-600': !isMenuCollapsed && !isMobile,
                            // 'rounded-[8px] bg-B-600': isMobile,
                        }
                    )}
                >
                    <i
                        className={CN('ri-add-circle-fill text-md text-B-600', {
                            '!text-B-50': !isMenuCollapsed && !isMobile,
                        })}
                    />
                    {(isMenuCollapsed || isMobile) && (
                        <p className="text-B-600 font-500 text-sm whitespace-nowrap duration-300 ease-in-out delay-150">
                            Start a New Chat
                        </p>
                    )}
                </button>
            </ButtonWithTooltip>
            <div className="bg-[#E5E7EB] h-[1px] w-full" />
            <div className="flex flex-col gap-y-3">
                <SideBarButton
                    label="Chat History"
                    type="primary"
                    icon="ri-chat-history-fill"
                    buttonSelectionType={SideBarButtonSelectionTypes.ChatHistorySelection}
                    isIconOnly={!isMenuCollapsed && !isMobile}
                />
                <SideBarButton
                    label="Explore KPLs"
                    type="primary"
                    icon="ri-stack-fill"
                    buttonSelectionType={SideBarButtonSelectionTypes.KplListSelection}
                    isIconOnly={!isMenuCollapsed && !isMobile}
                />
            </div>
        </div>
    );
};
