import { Button, Drawer, TextField, Tooltip } from '@kaya/kaya-ui-design-system-pb';
import React from 'react';
import { useMobile } from '../../../hooks';
import { useUserManageTable } from '../../../hooks/useUserManageTable';
import { CN, uniqueId } from '../../../utils';
import { Profile } from '../profile/profile';
import { UITableV2 } from '../table';
import { UserForm } from '../user-form';
import { useUserSubmission } from '../user-form/useUserSubmission';
import { IAdminFilterUserContainerProps } from '../user-management-container';

export const UserTableContainer = ({ filterUserCriteria }: IAdminFilterUserContainerProps) => {
    const isMobile = useMobile();
    const {
        tableColumnDefinition,
        tableData,
        isProfileViewDrawerOpen,
        setIsProfileViewDrawerOpen,
        isEditDrawerOpen,
        setIsEditDrawerOpen,
        scrollableRef,
        refetchData,
        setSearchTerm,
        tableElementId,
        loading,
        resetUserFormData,
        selectedProfile,
        isLoadingMore,
        tableLoadingText,
    } = useUserManageTable(filterUserCriteria);
    const { handleSubmit, hasErrors, validationErrorMsgs } = useUserSubmission(refetchData, true);

    return (
        <>
            <div className="admin-panel__table-section flex sm:w-full flex-col gap-y-4 overflow-auto w-full h-full">
                <div className="admin-panel__search flex sm:flex-col text-start sm:items-start items-center gap-x-20 justify-between">
                    <div className="flex gap-x-3">
                        <p className="text-N-600 text-start whitespace-nowrap">
                            Showing <span className="text-N-900 font-600">{tableData.length}</span> Users
                        </p>
                        <Tooltip
                            id={uniqueId()}
                            title={'Reload Users'}
                            tooltipType="minimal"
                            variant="dark"
                            place="right"
                        >
                            <i
                                onClick={() => refetchData()}
                                className={CN(
                                    'ri-refresh-line text-sm font-500 text-N-900 cursor-pointer hover:text-[#1D5BD7] hover:font-bold',
                                    {
                                        'animate-spin': loading,
                                    }
                                )}
                            />
                        </Tooltip>
                    </div>
                    <TextField
                        view="default"
                        iconBefore="ri-search-line"
                        placeholder="Search User by name"
                        wrapperClassName="w-full mr-[10px]"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(event.target.value)}
                    />
                </div>
                <div className="admin-panel__table w-full h-full">
                    <div
                        className={CN(
                            'w-full sm:overflow-x-scroll max-h-[calc(100vh-220px)] overflow-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 h-full',
                            {
                                'min-h-[400px]': !tableData?.length,
                            }
                        )}
                        ref={scrollableRef}
                        id={tableElementId}
                    >
                        <UITableV2
                            className="z-[0]"
                            width={100}
                            widthInPercentage={true}
                            tableData={tableData}
                            columnsArray={tableColumnDefinition}
                            bodyClassnames={tableData?.length >= 5 ? 'overflow-auto' : ''}
                            isLoading={loading || isLoadingMore}
                            isLoadMoreTriggered={isLoadingMore}
                            loadingText={tableLoadingText}
                            headerClassnames={tableData?.length <= 1 ? 'z-0' : ''}
                        />
                    </div>
                </div>
            </div>

            <Drawer
                isOpen={isEditDrawerOpen}
                onClose={() => {
                    setIsEditDrawerOpen(false);
                    resetUserFormData();
                }}
                title="Edit Basic Info"
                isHideClose={true}
                headerIcon="ri-contacts-line"
                fullWidth={isMobile}
                width={600}
                closableOnClickOutside={true}
                className="z-[999]"
                footer={
                    <div className="flex items-center justify-between gap-x-2">
                        {validationErrorMsgs?.length > 0 && (
                            <Tooltip
                                id={'validation-error-tooltip-user-section'}
                                tooltipType="detailed"
                                subText={
                                    <ul>
                                        {validationErrorMsgs?.map((data: any, key: any) => (
                                            <li key={key} className="flex items-center justify-start gap-3">
                                                <span>
                                                    <i className="ri-information-fill text-[18px]" />
                                                </span>
                                                <span>{data.name}</span>
                                            </li>
                                        ))}
                                    </ul>
                                }
                                variant="dark"
                            >
                                <span className="w-full text-R-600 flex items-center text-xs sm:gap-x-0 gap-x-2">
                                    <span> Ensure all fields are filled out correctly</span>{' '}
                                    <i className="sm:hidden block ri-question-line" />
                                </span>
                            </Tooltip>
                        )}
                        <div className="flex items-center justify-end gap-x-2 flex-grow">
                            <Button
                                view="outline"
                                appearance="primary"
                                size="medium"
                                onClick={() => {
                                    setIsEditDrawerOpen(false);
                                    resetUserFormData();
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                isDisabled={hasErrors}
                                view="solid"
                                appearance="primary"
                                size="medium"
                                onClick={() => {
                                    handleSubmit(false);
                                    setIsEditDrawerOpen(false);
                                    resetUserFormData();
                                }}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                }
                children={
                    <>
                        <div className="px-5 w-full h-full pb-[76px] overflow-auto">
                            <UserForm isEdit selectedEmail={selectedProfile.email} />
                        </div>
                    </>
                }
            />

            <Drawer
                isOpen={isProfileViewDrawerOpen}
                onClose={() => {
                    setIsProfileViewDrawerOpen(false);
                }}
                title="User Profile"
                fullWidth={isMobile}
                headerIcon="ri-contacts-line"
                width={600}
                closableOnClickOutside={true}
                className="z-[999]"
                isHideClose={true}
                footer={
                    <div className="flex items-center gap-x-[8px] justify-end">
                        <Button
                            view="outline"
                            appearance="primary"
                            size="medium"
                            onClick={() => {
                                setIsProfileViewDrawerOpen(false);
                            }}
                        >
                            Close
                        </Button>
                    </div>
                }
                children={
                    <>
                        <div className="px-5 w-full h-full pb-[76px] overflow-auto">
                            <Profile />
                        </div>
                    </>
                }
            />
        </>
    );
};
