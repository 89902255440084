import React from 'react';
import { MobileMenuButton } from '../buttons';
import { AdminPanelSectionSelectionTypes, useSideBarContext } from '../../../contexts';
import classNames from 'classnames';
import { useMobile } from '../../../hooks';

export const AdminPanelMenu = () => {
    const { setAdminPanelSelection, adminPanelSelection, handleMobileMenuIconClick } = useSideBarContext();
    const isMobile = useMobile();

    const handleMenuSelection = (selectedMenu: AdminPanelSectionSelectionTypes) => {
        setAdminPanelSelection(selectedMenu);
        if (isMobile) handleMobileMenuIconClick();
    };

    return (
        <div>
            <div className="flex justify-between items-center border-b border-b-N-200 z-[10]">
                <div className="chat_history_title py-[20px] sm:py-4 px-6 sm:px-3 flex gap-x-[10px] items-center border-b-N-200">
                    <p className="text-h6 sm:text-lg font-600 text-N-900">Admin Panel</p>
                </div>
                <MobileMenuButton />
            </div>
            <div className="flex flex-col gap-y-5">
                {/* KPL Management */}
                <div className="flex flex-col gap-y-3 px-6 py-4 border-b border-b-N-200">
                    <div className="flex items-center gap-x-1">
                        <i className="ri-archive-stack-fill text-lg text-N-600" />
                        <p className="text-md text-N-600">KPL Management</p>
                    </div>
                    <div
                        className=" pl-4 flex gap-x-1 items-center hover:cursor-pointer group"
                        onClick={() => {
                            handleMenuSelection(AdminPanelSectionSelectionTypes.KPL_AUTHORING);
                        }}
                    >
                        <i
                            className={classNames('ri-corner-down-right-fill text-sm group-hover:text-B-600', {
                                'text-B-600': adminPanelSelection === AdminPanelSectionSelectionTypes.KPL_AUTHORING,
                                'text-N-600': adminPanelSelection !== AdminPanelSectionSelectionTypes.KPL_AUTHORING,
                            })}
                        />
                        <p
                            className={classNames('text-sm group-hover:text-B-600', {
                                'text-B-600 font-500 underline underline-offset-2':
                                    adminPanelSelection === AdminPanelSectionSelectionTypes.KPL_AUTHORING,
                                'text-N-600': adminPanelSelection !== AdminPanelSectionSelectionTypes.KPL_AUTHORING,
                            })}
                        >
                            KPL Authoring
                        </p>
                    </div>
                </div>
                {/* User Management Management */}
                <div className="flex flex-col gap-y-3 px-6 py-4 border-b border-b-N-200">
                    <div className="flex items-center gap-x-1">
                        <i className="ri-user-settings-fill text-lg text-N-600" />
                        <p className="text-md text-N-700">User Management</p>
                    </div>
                    <div
                        className=" pl-4 flex gap-x-1 items-center hover:cursor-pointer group"
                        onClick={() => {
                            handleMenuSelection(AdminPanelSectionSelectionTypes.USER_AUTHORING);
                        }}
                    >
                        <i
                            className={classNames('ri-corner-down-right-fill text-sm group-hover:text-B-600', {
                                'text-B-600': adminPanelSelection === AdminPanelSectionSelectionTypes.USER_AUTHORING,
                                'text-N-600': adminPanelSelection !== AdminPanelSectionSelectionTypes.USER_AUTHORING,
                            })}
                        />
                        <p
                            className={classNames('text-sm group-hover:text-B-600', {
                                'text-B-600 font-500 underline underline-offset-2':
                                    adminPanelSelection === AdminPanelSectionSelectionTypes.USER_AUTHORING,
                                'text-N-600': adminPanelSelection !== AdminPanelSectionSelectionTypes.USER_AUTHORING,
                            })}
                        >
                            User Authoring
                        </p>
                    </div>
                </div>
                {/* Prompt Management */}
                <div className="flex flex-col gap-y-3 px-6 py-4 border-b border-b-N-200">
                    <div className="flex items-center gap-x-1">
                        <i className="ri-file-text-fill text-lg text-N-600" />
                        <p className="text-md text-N-600">Prompt Management</p>
                    </div>
                    <div
                        className=" pl-4 flex gap-x-1 items-center hover:cursor-pointer group"
                        onClick={() => {
                            handleMenuSelection(AdminPanelSectionSelectionTypes.PROMPT_AUTHORING);
                        }}
                    >
                        <i
                            className={classNames('ri-corner-down-right-fill text-sm group-hover:text-B-600', {
                                'text-B-600': adminPanelSelection === AdminPanelSectionSelectionTypes.PROMPT_AUTHORING,
                                'text-N-600': adminPanelSelection !== AdminPanelSectionSelectionTypes.PROMPT_AUTHORING,
                            })}
                        />
                        <p
                            className={classNames('text-sm group-hover:text-B-600', {
                                'text-B-600 font-500 underline underline-offset-2':
                                    adminPanelSelection === AdminPanelSectionSelectionTypes.PROMPT_AUTHORING,
                                'text-N-600': adminPanelSelection !== AdminPanelSectionSelectionTypes.PROMPT_AUTHORING,
                            })}
                        >
                            Prompt Authoring
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};
