import React, { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import CN from 'classnames';
import { CheckBox, EmptyState } from '@kaya/kaya-ui-design-system-pb';
import { useTable, Column, useRowSelect, useSortBy, useMountedLayoutEffect, HeaderProps } from 'react-table';
import './table.scss';
import { OverlayLoading } from './common';
import classNames from 'classnames';

export interface columnsArrayType {
    id?: string;
    Header: ((props: any) => JSX.Element) | string;
    accessor: string;
    isSortable?: boolean;
    sortType?: Function;
    width?: number;
    minWidth?: number;
    isCustomizable: boolean;
    headerPadding?: string;
    cellPadding?: string;
    alignment?: string;
    showHeader?: boolean;
    Cell: (props: any) => JSX.Element;
}

export interface TableHeightType {
    height: number;
    unit: 'px' | 'vh' | '%';
}

export interface IUITableV2Props {
    className?: string;
    columnsArray: columnsArrayType[];
    tableData: any;
    onChange?: any;
    allowRowSelection?: boolean;
    isColumnCustomizable?: boolean;
    width?: number;
    widthInPercentage?: boolean;
    addRowBorders?: boolean;
    addColumnBorders?: boolean;
    tableWrapperHeight?: TableHeightType;
    isLoading?: boolean;
    loadingText?: string;
    isLoadMoreTriggered?: boolean;
    tableRef?: RefObject<HTMLTableElement>;
    bodyClassnames?: string;
    headerClassnames?: string;
}

export const UITableV2 = ({
    className,
    columnsArray = [],
    tableData,
    allowRowSelection,
    onChange,
    isColumnCustomizable,
    isLoading,
    width = 952,
    widthInPercentage = false,
    addRowBorders = true,
    addColumnBorders = false,
    tableWrapperHeight,
    loadingText = 'Loading ...',
    isLoadMoreTriggered = false,
    tableRef,
    bodyClassnames,
    headerClassnames,
    ...restProps
}: IUITableV2Props) => {
    const columns: Column[] = useMemo(() => columnsArray as Column[], [tableData]);
    const data = useMemo(() => tableData, [tableData]);

    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const UITableClassName = CN(
        'relative',
        tableWrapperHeight ? `h-[${tableWrapperHeight.height}${tableWrapperHeight.unit}]` : 'h-auto'
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        allColumns,
        selectedFlatRows,
        state: { selectedRowIds },
    }: any = useTable(
        {
            columns,
            data,
        },
        useSortBy,
        useRowSelect
    );

    useMountedLayoutEffect(() => {
        onChange && onChange(selectedFlatRows.map((row: any) => row.original));
    }, [selectedFlatRows]);

    const handleDropdownClick = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleDropdownOutsideClick = () => {
        setIsDropdownOpen(false);
        document.removeEventListener('click', handleDropdownOutsideClick);
    };

    return (
        <>
            <div
                {...restProps}
                className={UITableClassName}
                style={{ width: widthInPercentage ? `${width}%` : `${width}px` }}
            >
                <div className="flex tableFixHead" style={{ width: widthInPercentage ? `${width}%` : `${width}px` }}>
                    <table
                        {...getTableProps()}
                        ref={tableRef}
                        className={CN(
                            'fixed_header ui-table bg-white border-[1px] border-solid border-N-200',
                            className
                        )}
                        style={{ width: widthInPercentage ? `${width}%` : `${width}px` }}
                    >
                        {/* Table header start from here */}
                        <thead className={CN('relative', headerClassnames)}>
                            {headerGroups.map((headerGroup: any) => (
                                <tr {...headerGroup.getFooterGroupProps()}>
                                    {headerGroup.headers.map((column: any) => (
                                        <th
                                            onClick={() =>
                                                column.isSortable && column.toggleSortBy(!column.isSortedDesc)
                                            }
                                            className="text-sm text-N-600 font-medium text-left"
                                            style={{
                                                padding: column.headerPadding ? column.headerPadding : '12px 8px',
                                                width: column.width ? `${column.width}px` : 'auto',
                                            }}
                                        >
                                            <span
                                                className="flex items-center gap-[8px]"
                                                style={{
                                                    justifyContent: column.alignment ? column.alignment : 'start',
                                                }}
                                            >
                                                <span className="flex h-[16px] items-center justify-center">
                                                    {column.showHeader && column.render('Header')}
                                                </span>
                                                {column.isSortable && (
                                                    <span className="flex w-[16px] h-[16px] items-center justify-center">
                                                        <span className="flex">
                                                            {column.isSorted ? (
                                                                column.isSortedDesc ? (
                                                                    <i className="ri-sort-asc"></i>
                                                                ) : (
                                                                    <i className="ri-sort-desc"></i>
                                                                )
                                                            ) : (
                                                                <i className="ri-sort-asc"></i>
                                                            )}
                                                        </span>
                                                    </span>
                                                )}
                                            </span>
                                        </th>
                                    ))}
                                    {isColumnCustomizable && (
                                        <th>
                                            <div
                                                className="dropdown-main absolute right-0 top-0 z-[9999]"
                                                ref={dropdownRef}
                                                onMouseLeave={() => {
                                                    document.addEventListener('click', handleDropdownOutsideClick);
                                                }}
                                                onMouseEnter={() => {
                                                    document.removeEventListener('click', handleDropdownOutsideClick);
                                                }}
                                            >
                                                <div
                                                    className="absolute right-[16px] top-[12px] z-[9999] w-[16px] h-[16px] flex items-center justify-center"
                                                    onClick={handleDropdownClick}
                                                >
                                                    <i className="ri-settings-3-line text-[24px] text-N-600 cursor-pointer customize-columns-icon sm:hidden"></i>
                                                </div>
                                                {isDropdownOpen && (
                                                    <div className="absolute bg-white z-[9999] w-[190px] h-auto py-[8px] right-[15px] top-[36px] shadow-md rounded-[2px]">
                                                        <div className="px-[16px] py-[8px] flex flex-col">
                                                            {allColumns.map(
                                                                (column: any) =>
                                                                    column.isCustomizable === true && (
                                                                        <div key={column.id} className="py-[8px]">
                                                                            <CheckBox
                                                                                label={column.Header}
                                                                                {...column.getToggleHiddenProps()}
                                                                            />
                                                                        </div>
                                                                    )
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </th>
                                    )}
                                </tr>
                            ))}
                        </thead>
                        {/* table body start from here */}
                        <tbody {...getTableBodyProps()} className={CN('min-h-[648px]', bodyClassnames)}>
                            {isLoading ? (
                                <OverlayLoading isLoadMoreTriggered={isLoadMoreTriggered} loadingText={loadingText} />
                            ) : (
                                rows?.length <= 0 &&
                                !isLoading && (
                                    <div className="table-info-wrapper w-[99.8%] h-[99.7%]  absolute bg-white z-50 top-[200px] left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex justify-center items-center">
                                        <div className="w-[400px] h-[400px] flex items-center justify-center flex-col">
                                            <EmptyState
                                                className="class"
                                                image="empty-state-no-result"
                                                title="No Results found"
                                                subtitle="Adjust your filters and try again"
                                            />
                                        </div>
                                    </div>
                                )
                            )}

                            {rows.length > 0 &&
                                rows.map((row: any) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map((cell: any) => {
                                                return (
                                                    <td
                                                        {...cell.getCellProps({
                                                            style: {
                                                                minWidth: cell.column.minWidth,
                                                                width: cell.column.width,
                                                                padding: cell.column.cellPadding
                                                                    ? cell.column.cellPadding
                                                                    : '8px 10px',
                                                            },
                                                        })}
                                                        className={classNames('text-sm text-N-600', {
                                                            'border-b-[1px] border-b-solid border-b-N-200':
                                                                addRowBorders,
                                                            'border-r-[1px] border-r-solid border-r-N-200':
                                                                addColumnBorders,
                                                        })}
                                                    >
                                                        <span
                                                            className="flex"
                                                            style={{
                                                                justifyContent: cell.column.alignment
                                                                    ? cell.column.alignment
                                                                    : 'start',
                                                            }}
                                                        >
                                                            {cell.render('Cell')}
                                                        </span>
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};
