import { Tooltip } from '@kaya/kaya-ui-design-system-pb';
import { SideBarButtonSelectionTypes, useSideBarContext } from '../../../contexts';
import { CN, uniqueId } from '../../../utils';
import classNames from 'classnames';
import { useMobile } from '../../../hooks';
import { ButtonWithTooltip } from './ButtonWithTooltip';

interface ISideBarButtonProps {
    buttonSelectionType?: SideBarButtonSelectionTypes;
    icon: string;
    type: 'primary' | 'secondary' | 'action';
    label: string;
    onClick?: () => void;
    isIconOnly?: boolean;
}

export const SideBarButton = ({ onClick, label, buttonSelectionType, icon, type, isIconOnly }: ISideBarButtonProps) => {
    const { isCollapsed, setIsCollapsed, sideBarButtonSelection, setSideBarButtonSelection } = useSideBarContext();
    const isMobile = useMobile();

    const handleButtonClick = () => {
        if (buttonSelectionType) {
            setSideBarButtonSelection(buttonSelectionType);
            if (!isMobile) {
                if (isCollapsed) {
                    setIsCollapsed(false);
                } else {
                    buttonSelectionType === sideBarButtonSelection && setIsCollapsed(true);
                }
            } else {
                setIsCollapsed(true);
            }
        }
        onClick?.();
    };

    return (
        <ButtonWithTooltip title={label} showTooltip={isIconOnly && !isMobile}>
            <button
                onClick={handleButtonClick}
                className={CN(
                    'flex items-center gap-x-3 px-4 py-[10px] rounded-[8px] whitespace-nowrap duration-300 ease-in-out delay-150',
                    {
                        'bg-B-100 animate-jump animate-once animate-duration-300 ':
                            sideBarButtonSelection === buttonSelectionType,
                        'hover:bg-N-200': sideBarButtonSelection !== buttonSelectionType && !isMobile,
                        'h-[40px] w-[40px] rounded-[4px] justify-center': isIconOnly && !isMobile,
                    }
                )}
            >
                <i
                    className={CN(`${icon} text-md`, {
                        'text-B-600': sideBarButtonSelection === buttonSelectionType,
                        'text-N-500': sideBarButtonSelection !== buttonSelectionType,
                    })}
                />
                {(!isIconOnly || isMobile) && (
                    <p
                        className={classNames(
                            'text-sm font-500 duration-300 ease-in-out delay-150  whitespace-nowrap',
                            {
                                'text-B-600': sideBarButtonSelection === buttonSelectionType,
                                'text-N-500': sideBarButtonSelection !== buttonSelectionType,
                            }
                        )}
                    >
                        {label}
                    </p>
                )}
            </button>
        </ButtonWithTooltip>
    );
};
